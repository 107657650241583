import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { NotifyService } from 'config';
import { PAGINATION_TYPE, PayloadActionData, RESPONSE_TYPE } from 'models';
import {
  ADDRESS_LIST_RESPONSE,
  ADD_EDIT_ADDRESS_PARAMS,
  DELETE_ADDRESS_PARAMS,
  GET_ADDRESS_BY_POSTAL_CODE_RESPONSE,
  GET_REGION_LIST_PARAMS,
  REGION_LIST_RESPONSE,
} from 'models/parent/address';
import { call, put, takeLatest } from 'redux-saga/effects';
import { parentApi } from 'services/parent';
import { setGlobalLoading } from 'store/reducers/global';
import {
  getAddressByPostalCode,
  updateAddressList,
  updateRegionList,
} from 'store/reducers/parent';
import {
  ADD_ADDRESS_REQUEST,
  DELETE_ADDRESS_REQUEST,
  EDIT_ADDRESS_REQUEST,
  GET_ADDRESS_BY_CODE,
  GET_ADDRESS_LIST_REQUEST,
  GET_REGION_LIST_REQUEST,
} from 'store/reducers/parent/actionTypes';

function* getAddressListRequest() {
  try {
    const response: AxiosResponse<RESPONSE_TYPE<ADDRESS_LIST_RESPONSE[]>> =
      yield call(parentApi.getAddressList);
    yield put(updateAddressList(response.data.data));
  } catch (error) {
    NotifyService.error(error);
  }
}

function* addAddressRequest(
  action: PayloadAction<PayloadActionData<ADD_EDIT_ADDRESS_PARAMS>>,
) {
  try {
    yield put(setGlobalLoading(true));
    yield call(parentApi.addAddress, action.payload.data);
    action.payload.onSuccess?.();
  } catch (error) {
    action.payload.onFailed?.(error);
    NotifyService.error(error);
  } finally {
    yield put(setGlobalLoading(false));
    action.payload.onFinally?.();
  }
}

function* editAddressRequest(
  action: PayloadAction<PayloadActionData<ADD_EDIT_ADDRESS_PARAMS>>,
) {
  try {
    yield put(setGlobalLoading(true));
    yield call(parentApi.editAddress, action.payload.data);
    action.payload.onSuccess?.();
  } catch (error) {
    action.payload.onFailed?.(error);
    NotifyService.error(error);
  } finally {
    yield put(setGlobalLoading(false));
    action.payload.onFinally?.();
  }
}

function* getRegionListRequest(
  action: PayloadAction<PayloadActionData<GET_REGION_LIST_PARAMS>>,
) {
  try {
    const response: AxiosResponse<
      RESPONSE_TYPE<PAGINATION_TYPE<REGION_LIST_RESPONSE[]>>
    > = yield call(parentApi.getRegionList, action.payload.data);

    yield put(updateRegionList(response.data.data));
  } catch (error) {
    NotifyService.error(error);
  }
}

function* getAddressByPostalCodeRequest(
  action: PayloadAction<PayloadActionData<{ id: number }>>,
) {
  try {
    const response: AxiosResponse<
      RESPONSE_TYPE<GET_ADDRESS_BY_POSTAL_CODE_RESPONSE>
    > = yield call(parentApi.getAddressByPostalCode, action.payload.data.id);

    yield put(getAddressByPostalCode(response.data.data));
  } catch (error) {
    NotifyService.error(error);
  }
}

function* deleteAddressRequest(
  action: PayloadAction<PayloadActionData<DELETE_ADDRESS_PARAMS>>,
) {
  try {
    yield put(setGlobalLoading(true));
    yield call(parentApi.deleteAddress, action.payload.data);
    action.payload.onSuccess?.();
  } catch (error) {
    action.payload.onFailed?.();
    NotifyService.error(error);
  } finally {
    yield put(setGlobalLoading(false));
    action.payload.onFinally?.();
  }
}

function* addressSaga() {
  yield takeLatest(GET_ADDRESS_LIST_REQUEST, getAddressListRequest);
  yield takeLatest(ADD_ADDRESS_REQUEST, addAddressRequest);
  yield takeLatest(EDIT_ADDRESS_REQUEST, editAddressRequest);
  yield takeLatest(GET_REGION_LIST_REQUEST, getRegionListRequest);
  yield takeLatest(GET_ADDRESS_BY_CODE, getAddressByPostalCodeRequest);
  yield takeLatest(DELETE_ADDRESS_REQUEST, deleteAddressRequest);
}
export default addressSaga;
