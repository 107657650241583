import { STORAGE_KEY } from 'constants/global';
import { MessagePayload, onMessage } from 'firebase/messaging';
import { EVENT_FIREBASE_PARENT } from 'models/notifications';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'utils';
import { useHandleFirebase } from './useHandleFireBase';

interface IUseNotificationOutputValue {
  // value
}

interface IUseNotificationOutputAction {
  // action
}

export const useHandleNotification = (): [
  IUseNotificationOutputValue,
  IUseNotificationOutputAction,
] => {
  const token = getLocalStorage(STORAGE_KEY.ACCESS_TOKEN);
  const [{ messaging }, { requestPermission }] = useHandleFirebase();

  useEffect(() => {
    window.addEventListener('beforeunload', function () {
      removeLocalStorage(STORAGE_KEY.PERMISSION_NOTIFICATION);
      // removeLocalStorage(STORAGE_KEY.DEVICE_TOKEN);
    });
  }, []);

  useEffect(() => {
    if (messaging && token) {
      if (!getLocalStorage(STORAGE_KEY.PERMISSION_NOTIFICATION)) {
        // If permission hasn't been granted, request it
        requestPermission?.();
      }

      // Listen for incoming messages
      onMessage(messaging, async (payload: MessagePayload) => {
        if (
          payload &&
          (payload.data.objectTypeId ===
            EVENT_FIREBASE_PARENT.AUTO_PAYMENT_FOR_JOB ||
            payload.data.objectTypeId ===
              EVENT_FIREBASE_PARENT.ADD_SITTER_BY_ADMIN)
        ) {
          setLocalStorage(STORAGE_KEY.IS_NEWEST_NOTIFY, true.toString());
          toast.info(
            (payload.notification.body ??
              'You have new notification') as string,
          );
          toast.clearWaitingQueue();
        }
      });
    }
    // eslint-disable-next-line
  }, [token, messaging, requestPermission]);

  // Return the state of whether to open a toast notification
  return [{}, {}];
};
