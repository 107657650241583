import { Box, Stack, Typography } from '@mui/material';
import { EmptyUI } from 'components';
import JobItem from 'components/common/JobItem';
import ScrollToLoadMore from 'components/common/ScrollLoadMore';
import { EORDER_SORT } from 'models/common';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { getJobListAction } from 'store/reducers/job/actionTypes';
import { JobListWrapper } from './styles';

function ListJobs() {
  const dispatch = useAppDispatch();
  const { parent } = useAppSelector((state) => state.auth);
  const { jobList } = useAppSelector((state) => state.jobs);

  const _params = {
    limit: 10,
    parent_id: parent?.parent_id,
    sort_column: 'start_datetime',
    sort_direction: EORDER_SORT.ASC,
  };

  useEffect(() => {
    dispatch(
      getJobListAction({
        data: {
          ..._params,
          page: 1,
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMore = () => {
    dispatch(
      getJobListAction({
        data: {
          ..._params,
          page: jobList.metadata.page + 1,
        },
      }),
    );
  };

  return (
    <Box>
      <Stack
        mb={2.5}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="Web_Title_22">Bookings</Typography>
      </Stack>

      <JobListWrapper spacing={2}>
        {!!jobList.records?.length ? (
          <ScrollToLoadMore
            data={jobList.records}
            metadata={jobList.metadata}
            loadMore={loadMore}
          >
            <Stack spacing={2}>
              {jobList.records?.map((booking) => (
                <JobItem key={booking.id} data={booking} />
              ))}
            </Stack>
          </ScrollToLoadMore>
        ) : (
          <Stack justifyContent="center" alignItems="center">
            <Box position="relative" mt={3.75}>
              <EmptyUI content="You don’t have any bookings yet." />
            </Box>
          </Stack>
        )}
      </JobListWrapper>
    </Box>
  );
}

export default ListJobs;
