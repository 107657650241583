import { Stack, Typography } from '@mui/material';
import { BoldHeartIcon } from 'assets';
import { ENUM_SECTION_NAME_CHECK_LIST, ITEM_CHECK_LIST } from 'models/sitter';
import { StyledCertificationsItem, StyledItemTypography } from './styles';

interface SitterPanelProps {
  panelItem: {
    items: ITEM_CHECK_LIST[];
    section_title: string;
  };
}

const SitterPanel = ({ panelItem }: SitterPanelProps) => {
  const renderNoneText = () => {
    let noneText = '';
    switch (panelItem.section_title) {
      case ENUM_SECTION_NAME_CHECK_LIST.COMFORTABLE_PETS:
        noneText = 'No';
        break;
      case ENUM_SECTION_NAME_CHECK_LIST.OVERNIGHT_SIT:
        noneText = 'No';
        break;
      default:
        noneText = 'None';
        break;
    }
    return (
      <Typography variant="Web_Label_14" color="text.secondary">
        {noneText}
      </Typography>
    );
  };

  return (
    <StyledCertificationsItem direction="row" spacing={1}>
      <BoldHeartIcon color="primary" />
      <Stack pt={0.5} spacing={1}>
        <Typography variant="Web_Label_16" color="primary">
          {panelItem.section_title}
        </Typography>
        {panelItem.items.length === 0
          ? renderNoneText()
          : panelItem.items.map((item) => (
              <StyledItemTypography key={item.id} variant="Web_Label_14">
                {item.name}
              </StyledItemTypography>
            ))}
      </Stack>
    </StyledCertificationsItem>
  );
};

export default SitterPanel;
