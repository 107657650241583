import styled from '@emotion/styled';
import { Button, Menu, Typography } from '@mui/material';
import { theme } from 'theme';

export const NotifyPopupRoot = styled(Menu)`
  & .MuiPaper-root {
    width: 379px;
    border-radius: 10px;
    gap: 15px;
    padding: 20px;
  }
`;

export const NotifyTitle = styled(Typography)`
  font-size: 22px;
  font-weight: 700;
  color: ${theme.palette.black.dark};
`;

export const ViewAllButton = styled(Button)`
  width: 100%;
  height: 40px;
  margin-top: 20px;
`;
