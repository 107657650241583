import { Avatar, Box, Button, Divider, Stack, Typography } from '@mui/material';
import { APP_IMG, ArrowLeftIcon, LocationOn } from 'assets';
import { Header } from 'components';
import PopupConfirm, { TYPE_ICON } from 'components/popup/PopupConfirm';
import { NotifyService } from 'config';
import { STORAGE_KEY } from 'constants/global';
import { SYS_MESS } from 'constants/systemMessage';
import { ACTION_TYPE, MODAL_ACTION, RESPONSE_TYPE } from 'models';
import { REVIEW_TYPE, SITTER_TYPE } from 'models/sitter';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { APP_ROUTES } from 'routers/routes';
import { overviewApi } from 'services/overview';
import { sitterApi } from 'services/sitter';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setGlobalLoading } from 'store/reducers/global';
import { updateACRequestSitterStatus } from 'store/reducers/overview';
import { getMyRequestAction } from 'store/reducers/overview/actionTypes';
import { getLocalStorage, pluralString } from 'utils';
import {
  AboutMeSection,
  ExperienceSection,
  ReviewsSection,
} from './components';
import {
  StyledBoxProfile,
  StyledContainer,
  StyledLink,
  StyledTypography,
  TopBox,
} from './styles';

const SitterDetail = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [sitter, setSitter] = useState<SITTER_TYPE>();
  const [sitterData, setSitterData] = useState<RESPONSE_TYPE<REVIEW_TYPE>>();
  const [modalAction, setModalAction] = useState<MODAL_ACTION<any>>();

  const { globalLoading } = useAppSelector((state) => state.global);
  const { sitterId, acRequestStatus, baseRequest } = useAppSelector(
    (state) => state.overview,
  );

  const { id } = useParams();

  const onBack = () => {
    navigate(APP_ROUTES.OVERVIEW.to);
  };

  const getSitter = async () => {
    dispatch(setGlobalLoading(true));
    await sitterApi
      .getDetail(id)
      .then(({ data: { data } }) => setSitter(data))
      .catch((e) => {
        NotifyService.error(e);
        onBack();
      })
      .finally(() => dispatch(setGlobalLoading(false)));
  };

  const getReview = async () => {
    dispatch(setGlobalLoading(true));
    await sitterApi
      .getReview({ user_id: id })
      .then((data) => setSitterData(data.data))
      .catch((e) => {
        NotifyService.error(e);
        onBack();
      })
      .finally(() => dispatch(setGlobalLoading(false)));
  };

  useEffect(() => {
    getSitter();
    getReview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onOpenModalAction = async (action: ACTION_TYPE) => {
    dispatch(setGlobalLoading(true));
    const response = await overviewApi
      .updateStatusSitter(sitterId, action)
      .catch((error) => NotifyService.error(error))
      .finally(() => dispatch(setGlobalLoading(false)));

    if (response) {
      setModalAction({ type: action });
      dispatch(updateACRequestSitterStatus(action));
      dispatch(
        getMyRequestAction({
          data: { accessToken: getLocalStorage(STORAGE_KEY.ACCESS_TOKEN) },
        }),
      );
    }
  };

  const onCloseModalAction = () => {
    setModalAction(undefined);
  };

  useEffect(() => {
    if (baseRequest) return;
    dispatch(
      getMyRequestAction({
        data: { accessToken: getLocalStorage(STORAGE_KEY.ACCESS_TOKEN) },
      }),
    );
  }, [dispatch, id, baseRequest]);

  if (globalLoading) return <></>;
  return (
    <Box>
      <Header />
      <main>
        <TopBox />
        <StyledContainer>
          <StyledBoxProfile direction={{ xs: 'column', md: 'row' }}>
            <StyledLink
              direction="row"
              alignItems="center"
              spacing={1}
              onClick={onBack}
            >
              <ArrowLeftIcon />
              <Typography lineHeight="21px" variant="Web_Label_18">
                Back to Request
              </Typography>
            </StyledLink>
            <Stack
              width="100%"
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Stack
                direction={{ xs: 'column', md: 'row' }}
                justifyContent={{ xs: 'center', md: 'start' }}
                alignItems="center"
                spacing={{ xs: 1, md: 1.5 }}
              >
                <Avatar
                  alt="avatar_nanny"
                  src={sitter?.sitter.profile_picture || APP_IMG.defaultAvatar}
                  sx={{
                    width: { xs: 90, md: 100 },
                    height: { xs: 90, md: 100 },
                  }}
                />
                <Stack spacing={1}>
                  <Typography
                    variant="Web_Title_22"
                    sx={{ textTransform: 'capitalize' }}
                    textAlign={{ xs: 'center', md: 'start' }}
                  >
                    {sitter?.sitter.first_name} {sitter?.sitter.last_name}
                  </Typography>
                  <Stack
                    direction="row"
                    spacing={{ xs: 1, md: 2 }}
                    sx={{ opacity: 0.5 }}
                    alignItems="center"
                  >
                    <StyledTypography
                      display={{ xs: 'none', md: 'block' }}
                      variant="Web_Label_18"
                    >
                      Total Hours:&nbsp;
                      {pluralString('hour', sitter?.sitter.salary_per_hour)}
                    </StyledTypography>
                    <Divider orientation="vertical" flexItem />
                    <Stack direction="row" spacing={0.5} alignItems="center">
                      <LocationOn
                        sx={{ display: { xs: 'none', md: 'block' } }}
                      />
                      <StyledTypography variant="Web_Label_18">
                        {sitter?.sitter.address?.country}
                      </StyledTypography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              {acRequestStatus === ACTION_TYPE.PENDING && (
                <>
                  <Button
                    sx={{ display: { xs: 'block', md: 'none' } }}
                    fullWidth
                    variant="contained"
                    onClick={() => onOpenModalAction(ACTION_TYPE.ACCEPTED)}
                  >
                    Accept
                  </Button>
                  <Button
                    sx={{ display: { xs: 'block', md: 'none' } }}
                    fullWidth
                    onClick={() => onOpenModalAction(ACTION_TYPE.REJECTED)}
                  >
                    Reject
                  </Button>
                  <Stack
                    direction={{ xs: 'column-reverse', md: 'row' }}
                    spacing={1}
                  >
                    <Button
                      sx={{ display: { xs: 'none', md: 'block' } }}
                      onClick={() => onOpenModalAction(ACTION_TYPE.REJECTED)}
                    >
                      Reject
                    </Button>
                    <Button
                      sx={{ display: { xs: 'none', md: 'block' } }}
                      variant="contained"
                      onClick={() => onOpenModalAction(ACTION_TYPE.ACCEPTED)}
                    >
                      Accept
                    </Button>
                  </Stack>
                </>
              )}
            </Stack>
          </StyledBoxProfile>
          <Stack pt={4} spacing={4}>
            <AboutMeSection bio={sitter?.sitter.bio ?? ''} />
            <ExperienceSection checkList={sitter?.checkLists ?? []} />
            <ReviewsSection reviews={sitterData} />
          </Stack>
        </StyledContainer>
      </main>
      {modalAction?.type === ACTION_TYPE.ACCEPTED && (
        <PopupConfirm
          typeIcon={TYPE_ICON.CHECK}
          title={SYS_MESS.SUCCESS.SITTER_ACCEPTED}
          content={
            <Typography variant="Web_Label_14">
              The sitter profile has been accepted. A trial date will be
              arranged for you shortly.
            </Typography>
          }
          textButton="Back to Overview"
          hrefButton={APP_ROUTES.OVERVIEW.to}
          handleClose={onCloseModalAction}
        />
      )}
      {modalAction?.type === ACTION_TYPE.REJECTED && (
        <PopupConfirm
          typeIcon={TYPE_ICON.CANCEL}
          title={SYS_MESS.SUCCESS.SITTER_REJECTED}
          content={
            <Typography variant="Web_Label_14">
              The sitter profile has been rejected.
            </Typography>
          }
          textButton="Back to Overview"
          hrefButton={APP_ROUTES.OVERVIEW.to}
          handleClose={onCloseModalAction}
        />
      )}
    </Box>
  );
};

export default SitterDetail;
