import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NOTIFICATION_DATA } from 'models/notifications';

export interface NotificationType {
  notifications: NOTIFICATION_DATA;
  notificationsOnBell: NOTIFICATION_DATA;
  isNewestNotify: boolean;
}

const initialState: NotificationType = {
  notifications: {
    metadata: {
      page: 0,
      limit: 0,
      page_count: 0,
      total_pages: 0,
      total_count: 0,
    },
    records: [],
  },
  notificationsOnBell: {
    metadata: {
      page: 0,
      limit: 0,
      page_count: 0,
      total_pages: 0,
      total_count: 0,
    },
    records: [],
  },
  isNewestNotify: false,
};

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotificationList(
      state: NotificationType,
      action: PayloadAction<NOTIFICATION_DATA>,
    ) {
      state.notifications = action.payload;
    },
    setNotificationListOnBell(
      state: NotificationType,
      action: PayloadAction<NOTIFICATION_DATA>,
    ) {
      state.notificationsOnBell = action.payload;
    },
    setNewestNotify(state: NotificationType, action: PayloadAction<boolean>) {
      state.isNewestNotify = action.payload;
    },
  },
});

// Actions
export const {
  setNotificationList,
  setNotificationListOnBell,
  setNewestNotify,
} = notificationSlice.actions;

export default notificationSlice.reducer;
