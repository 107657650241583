import { APIInstance } from 'config';
import { API } from 'constants/apiEndpoints';
import {
  CREATE_DEVICE_TOKEN_PAYLOAD,
  DELETE_DEVICE_TOKEN_PAYLOAD,
  GET_NOTIFICATION_PARAMS,
} from 'models/notifications';

export const notificationsAPI = {
  createDeviceToken: async (data: CREATE_DEVICE_TOKEN_PAYLOAD) => {
    return APIInstance.post(API.NOTIFICATIONS.CREATE_DEVICE_TOKEN, data);
  },
  deleteDeviceToken: async (data: DELETE_DEVICE_TOKEN_PAYLOAD) => {
    return APIInstance.delete(API.NOTIFICATIONS.DELETE_DEVICE_TOKEN, { data });
  },
  getList: async (data: GET_NOTIFICATION_PARAMS) => {
    return APIInstance.get(API.NOTIFICATIONS.GET_LIST, {
      params: data,
    });
  },
  markAsRead: async (id: string) => {
    return APIInstance.patch(`${API.NOTIFICATIONS.MARK_AS_READ(id)}`);
  },
  markReadAll: async () => {
    return APIInstance.patch(API.NOTIFICATIONS.MARK_ALL_AS_READ);
  },
};
