import { STORAGE_KEY } from 'constants/global';
// Import the functions you need from the SDKs you need
import { FirebaseApp, initializeApp } from 'firebase/app';
import 'firebase/messaging';
import { Messaging, getMessaging, getToken } from 'firebase/messaging';
import { PERMISSION_TYPE } from 'models/notifications';
import { useEffect, useState } from 'react';
import { notificationsAPI } from 'services/notifications';
import { getLocalStorage, setLocalStorage } from 'utils';

interface IConfigFireBaseOutputValue {
  messaging: Messaging | null;
}

interface IConfigFireBaseOutputAction {
  requestPermission: (() => Promise<void>) | undefined;
}

// Remove device token to the server
export const removeDeviceToken = async () => {
  if (getLocalStorage(STORAGE_KEY.DEVICE_TOKEN)) {
    await notificationsAPI
      .deleteDeviceToken({
        fcm_token: getLocalStorage(STORAGE_KEY.DEVICE_TOKEN),
      })
      .catch((error) => console.log(error))
      .finally();
  }
};

/**
 * Custom hook to handle Firebase configuration and messaging.
 * Initializes Firebase, obtains device token, requests permission, and handles notification registration.
 * Returns messaging instance and requestPermission function.
 */
export const useHandleFirebase = (): [
  IConfigFireBaseOutputValue,
  IConfigFireBaseOutputAction,
] => {
  // TODO: Add SDKs for Firebase products that you want to use
  // https://firebase.google.com/docs/web/setup#available-libraries

  // Firebase Config values imported from .env file
  // Note: For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const validKeyPair = process.env.REACT_APP_FIREBASE_VAPID_KEY;

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };

  const [messaging, setMessaging] = useState<Messaging | null>(null);
  const [requestPermission, setRequestPermission] = useState<
    (() => Promise<void>) | undefined
  >(undefined);

  useEffect(() => {
    // Initialize Firebase
    const app: FirebaseApp = initializeApp(firebaseConfig);

    // Initialize Firebase Cloud Messaging and get a reference to the service
    setMessaging(getMessaging(app));

    //Get device token from firebase
    const getDeviceToken = async () => {
      return await getToken(getMessaging(app), {
        vapidKey: validKeyPair,
      }).catch((error) => console.log(error, 'getDeviceToken'));
    };

    // Send device token to the server
    const sendDeviceToken = async () => {
      const deviceToken = await getDeviceToken();
      setLocalStorage(STORAGE_KEY.DEVICE_TOKEN, deviceToken as string);

      if (deviceToken) {
        //  send device token
        await notificationsAPI
          .createDeviceToken({
            fcm_token: deviceToken,
          })
          .catch((error) => console.log(error));
      }
    };

    //Requesting permission using Notification API
    const requestingPermission: () => Promise<void> = async () => {
      const permission = await Notification.requestPermission();

      if (permission === PERMISSION_TYPE.GRANTED) {
        setLocalStorage(
          STORAGE_KEY.PERMISSION_NOTIFICATION,
          PERMISSION_TYPE.GRANTED,
        );
        sendDeviceToken();
      }

      if (permission === PERMISSION_TYPE.DENIED) {
        setLocalStorage(
          STORAGE_KEY.PERMISSION_NOTIFICATION,
          PERMISSION_TYPE.DENIED,
        );
      }
    };
    setRequestPermission(() => requestingPermission);
    return () => {
      // Cleanup logic if needed
    };
    // eslint-disable-next-line
  }, []);

  // Return messaging instance and requestPermission function
  return [{ messaging }, { requestPermission }];
};
