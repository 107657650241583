import { Box, Stack, Typography } from '@mui/material';
import { APP_IMG } from 'assets';

interface Props {
  content: string;
}

export const EmptyUI: React.FC<Props> = ({ content }) => {
  return (
    <Stack justifyContent="center" alignItems="center">
      <Box position="relative" mt={3.75}>
        <img src={APP_IMG.backgroundEmpty} alt="bg_empty" />
        <Typography
          position="absolute"
          bottom={50}
          left={0}
          sx={{ opacity: 0.5 }}
          width="326px"
          textAlign="center"
          variant="Web_Label_14"
        >
          {content}
        </Typography>
      </Box>
    </Stack>
  );
};

export default EmptyUI;
