import { Divider, IconButton, Menu, MenuItem, Stack } from '@mui/material';
import MoreIcon from 'assets/icons/MoreIcon';
import { EmptyUI } from 'components';
import { NotifyTitle } from 'components/common/Header/components/NotifyBell/styles';
import NotifyItem from 'components/common/NotifyItem';
import ScrollLoadMore from 'components/common/ScrollLoadMore';
import { MouseEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setGlobalLoading } from 'store/reducers/global';
import { setNotificationList } from 'store/reducers/notifications';
import {
  getNotificationListAction,
  markAllAction,
} from 'store/reducers/notifications/actionTypes';

const NotificationPage = () => {
  const dispatch = useAppDispatch();
  const { notifications } = useAppSelector((state) => state.notifications);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const onRefreshData = () => {
    dispatch(setGlobalLoading(true));
    dispatch(
      getNotificationListAction({
        onSuccess: (data) => {
          dispatch(setGlobalLoading(false));
          dispatch(setNotificationList(data));
        },
        onFinally: () => {
          dispatch(setGlobalLoading(false));
        },
      }),
    );
  };

  const loadMore = () => {
    dispatch(
      getNotificationListAction({
        data: {
          page: notifications.metadata.page + 1,
          limit: 10,
        },
        onSuccess: (data) => {
          dispatch(setNotificationList(data));
        },
      }),
    );
  };

  const handleMarkAllasRead = () => {
    setAnchorEl(null);
    dispatch(
      markAllAction({
        onSuccess: () => {
          onRefreshData();
        },
      }),
    );
  };

  useEffect(() => {
    onRefreshData();
    // eslint-disable-next-line
  }, []);

  return (
    <Stack spacing={3} maxWidth={600}>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <NotifyTitle>Notifications</NotifyTitle>
        <IconButton
          onClick={(event: MouseEvent<HTMLElement>) =>
            setAnchorEl(event.currentTarget)
          }
        >
          <MoreIcon sx={{ width: 30, height: 30 }} />
        </IconButton>
      </Stack>
      <Menu
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={handleMarkAllasRead}>Mark All as Read</MenuItem>
      </Menu>
      <ScrollLoadMore
        data={notifications.records}
        metadata={notifications.metadata}
        loadMore={loadMore}
      >
        <Stack spacing={2}>
          {notifications.records.length > 0 ? (
            notifications.records.map((item, index) => (
              <Stack spacing={2}>
                <NotifyItem
                  key={index}
                  data={item}
                  onRefreshData={onRefreshData}
                />
                {notifications.records.length - 1 !== index && <Divider />}
              </Stack>
            ))
          ) : (
            <EmptyUI content="You don’t have any notifications yet." />
          )}
        </Stack>
      </ScrollLoadMore>
    </Stack>
  );
};

export default NotificationPage;
