import { SvgIcon, SvgIconProps } from '@mui/material';

const MarkAsReadIcon = ({ sx = {}, ...props }: SvgIconProps) => {
  return (
    <SvgIcon
      width="8"
      height="9"
      viewBox="0 0 8 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      sx={{ fill: 'none', ...sx }}
    >
      <circle cx="4" cy="4.5" r="4" fill="currentColor" />
    </SvgIcon>
  );
};

export default MarkAsReadIcon;
