import moment from 'moment';
import { OPTION_ITEM } from './common';
import { ADDRESS_LIST_RESPONSE } from './parent/address';

export enum AC_BASE_REQUEST_STATUS {
  PENDING_PAYMENT = 'pending_payment',
  SEARCH_REQUESTED = 'search_requested',
  SEARCH_FOUND = 'search_found',
  PARENT_ACCEPTED = 'parent_accepted',
  TRIAL_CREATED = 'trial_created',
  TRIAL_PAID = 'trial_paid',
  TRIAL_COMPLETED = 'trial_completed',
  ACTIVE = 'active',
  CLOSED = 'closed',
}

export const mapBaseRequestTitle = {
  [AC_BASE_REQUEST_STATUS.ACTIVE]: 'Active',
  [AC_BASE_REQUEST_STATUS.CLOSED]: 'Close',
  [AC_BASE_REQUEST_STATUS.PARENT_ACCEPTED]: 'Accepted',
  [AC_BASE_REQUEST_STATUS.PENDING_PAYMENT]: 'Pending Payment',
  [AC_BASE_REQUEST_STATUS.SEARCH_FOUND]: 'Search Found',
  [AC_BASE_REQUEST_STATUS.TRIAL_COMPLETED]: 'Trial Completed',
  [AC_BASE_REQUEST_STATUS.TRIAL_CREATED]: 'Trial Created',
  [AC_BASE_REQUEST_STATUS.TRIAL_PAID]: 'Trial Paid',
  [AC_BASE_REQUEST_STATUS.SEARCH_REQUESTED]: 'Search Requested',
};

export const mapBaseRequestColor = {
  [AC_BASE_REQUEST_STATUS.ACTIVE]: 'secondary.main',
  [AC_BASE_REQUEST_STATUS.CLOSED]: '#B6B6B6',
  [AC_BASE_REQUEST_STATUS.PARENT_ACCEPTED]: 'secondary.main',
  [AC_BASE_REQUEST_STATUS.PENDING_PAYMENT]: '#B6B6B6',
  [AC_BASE_REQUEST_STATUS.SEARCH_FOUND]: '#B6B6B6',
  [AC_BASE_REQUEST_STATUS.TRIAL_COMPLETED]: 'secondary.main',
  [AC_BASE_REQUEST_STATUS.TRIAL_CREATED]: 'secondary.main',
  [AC_BASE_REQUEST_STATUS.TRIAL_PAID]: 'secondary.main',
  [AC_BASE_REQUEST_STATUS.SEARCH_REQUESTED]: 'secondary.main',
};

export enum AC_BASE_REQUEST_MODAL_TYPE {
  TERMS = 'TERMS',
  ADDRESS = 'ADDRESS',
}

/* =========== INTERFACE ============ */

export interface DATA_CREATE_REQUEST_SUMMARY {
  addChild: ADD_CHILD_DATA;
  requestDetail: REQUEST_DETAIL_DATA;
  yourSignature: string;
}

export interface AN_CHILD_ITEM {
  id: string;
  name: string;
  age: OPTION_ITEM<number>;
}

export interface ADD_CHILD_DATA {
  children: AN_CHILD_ITEM[];
  isAccepted: boolean;
}

export interface REQUEST_DETAIL_ADDRESS {
  id: string | number;
  address: string;
}
export interface REQUEST_DETAIL_PET {
  id: string;
  name: string;
}
export interface REQUEST_DETAIL_DATA {
  startDate: moment.Moment;
  endDate: moment.Moment;
  startTime: Date;
  endTime: Date;
  dayOfWeeks: string[];
  specialRequirements: string;
  pets: string[];
  children: AN_CHILD_ITEM[];
  address: ADDRESS_LIST_RESPONSE;
  isAccepted: boolean;
}

export interface PARAMS_REQUEST_DETAIL {
  start_datetime: string;
  end_datetime: string;
  days_of_week: number[];
  address_id: string;
  children_ids: string[];
  pets: {
    cats: boolean;
    dogs: boolean;
  };
  special_requirements: string;
}
