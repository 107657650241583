import { Stack, Typography } from '@mui/material';
import MarkAsReadIcon from 'assets/icons/MarkAsReadIcon';
import { NOTIFICATION_ITEM } from 'models/notifications';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from 'routers/routes';
import { useAppDispatch } from 'store/hook';
import { markAsReadAction } from 'store/reducers/notifications/actionTypes';

interface NotificationBellProps {
  data?: NOTIFICATION_ITEM;
  onClose?: () => void;
  onRefreshData?: () => void;
}

const NotifyItem = ({
  data,
  onClose,
  onRefreshData,
}: NotificationBellProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleClickNotify = () => {
    dispatch(
      markAsReadAction({
        data: {
          id: data.id,
        },
        onSuccess: () => {
          onRefreshData?.();
          onClose?.();
          if (data?.metadata?.job_id) {
            navigate(APP_ROUTES.JOB.DETAIL.to(data.metadata?.job_id));
          }
        },
      }),
    );
  };

  return (
    <Stack
      flexDirection={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      gap={3}
      onClick={handleClickNotify}
      sx={{
        cursor: 'pointer',
        padding: '8px 5px',
        '&:hover': {
          backgroundColor: 'rgba(223, 120, 120, 0.1)',
          borderRadius: '8px',
        },
      }}
    >
      <Stack spacing={1}>
        <Typography variant="Web_Label_16">{data.title}</Typography>
        <Typography variant="Web_Label_14">
          {data.body ?? 'You have new notification'}
        </Typography>
      </Stack>
      {!data.isRead && (
        <MarkAsReadIcon sx={{ width: 11, height: 11, color: 'primary.main' }} />
      )}
    </Stack>
  );
};

export default NotifyItem;
