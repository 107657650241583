import { Box, Container, Grid, Stack } from '@mui/material';
import { Header } from 'components/common';
import MenuLeft from 'components/common/Menu/MenuLeft';
import { ReactNode } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { APP_ROUTES } from 'routers/routes';

export const MainLayout = () => {
  const { pathname } = useLocation();

  const excludedPaths = [
    APP_ROUTES.HOW_IT_WORKS.path,
    APP_ROUTES.CREATE_REQUEST.path,
    APP_ROUTES.CREDITS.BUY_PACKAGE.path,
    APP_ROUTES.CREDITS.PAYMENT.path,
    'jobs/detail',
    'jobs/edit',
  ];
  const isHideMenu = excludedPaths.some((path) => pathname.includes(path));

  const ContainerContentWrapper = ({ children }: { children?: ReactNode }) =>
    isHideMenu ? (
      <Stack paddingY={2.25}>{children}</Stack>
    ) : (
      <Container>
        <Grid container paddingY={2.25}>
          <Grid item display={{ xs: 'none', md: 'flex' }} md={2}>
            <MenuLeft />
          </Grid>
          <Grid item xs={12} md={10} pl={{ xs: 0, md: 10 }}>
            {children}
          </Grid>
        </Grid>
      </Container>
    );

  return (
    <Stack component={Box}>
      <Header />
      <main>
        <ContainerContentWrapper>
          <Outlet />
        </ContainerContentWrapper>
      </main>
    </Stack>
  );
};

export default MainLayout;
