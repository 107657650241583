import { Button, Divider, Stack, Typography } from '@mui/material';
import { PopupConfirm, TYPE_ICON } from 'components/popup';
import { DATE_TIME_FORMAT } from 'constants/date';
import { SYS_MESS } from 'constants/systemMessage';
import { AcceptExtensionReductionModal } from 'features/bookings/components';
import {
  mapBookingColor,
  mapBookingTitle,
  mapButtonTitle,
} from 'features/bookings/helpers';
import { formatDate } from 'helpers';
import {
  ACTION_TYPE,
  JOB_ITEM_RESPONSE,
  JOB_STATUS,
  MODAL_ACTION,
} from 'models';
import { memo, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from 'routers/routes';
import { useAppDispatch } from 'store/hook';
import { cancelJobAction } from 'store/reducers/job/actionTypes';
import { updateDataPaymentJob } from 'store/reducers/payments';
import { theme } from 'theme';
import { formatPrice, isBefore24h, pluralString } from 'utils';
import { StyledJobItem } from './styles';

interface BookingItemProps {
  data: JOB_ITEM_RESPONSE;
}

const JobItem = memo(({ data }: BookingItemProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [modalAction, setModalAction] = useState<MODAL_ACTION<ACTION_TYPE>>();

  const showButtons = ![
    JOB_STATUS.CANCELLED,
    JOB_STATUS.STARTED,
    JOB_STATUS.PENDING_COMPLETED,
    JOB_STATUS.COMPLETED,
  ].includes(data?.status);

  const handlePaymentJob = () => {
    dispatch(
      updateDataPaymentJob({
        id: data.id,
        total: data.total,
        isTrial: data.is_trial_job,
      }),
    );
    navigate(APP_ROUTES.JOB.PAYMENT.to);
  };

  const onSubmitCallback = () => {
    dispatch(
      cancelJobAction({
        data: { id: data.id },
        onSuccess: () => {
          setModalAction({ type: ACTION_TYPE.CANCELLED });
        },
      }),
    );
  };

  const onCloseModalAction = () => {
    setModalAction(undefined);
  };

  const getAddressInformation = () => {
    const { street, unit_number, block_number, postal_code } = data.address;
    return `${unit_number}, ${block_number} ${street}, Singapore ${postal_code}`;
  };

  const onClickButton = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    callback: any,
  ) => {
    e.preventDefault();
    callback?.();
  };

  return (
    <>
      <StyledJobItem
        component={NavLink}
        to={APP_ROUTES.JOB.DETAIL.to(data.id)}
        spacing={2}
      >
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent={{ xs: 'start', md: 'space-between' }}
          spacing={{ xs: 4, md: 0 }}
        >
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={{ xs: 1, md: 0.5 }}
          >
            <Typography variant="Web_Title_18"> {data.number}</Typography>
          </Stack>
          <Typography
            variant="Web_Label_18"
            color={mapBookingColor[data.status]}
          >
            {mapBookingTitle[data.status]}
          </Typography>
        </Stack>
        <Divider />
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={{ xs: 4, md: 14 }}
        >
          <Stack spacing={{ xs: 0.5 }}>
            <Typography variant="Web_Label_18" color={theme.palette.grey3.dark}>
              Start Date
            </Typography>
            <Typography variant="Web_Label_18">
              {formatDate(data.start_datetime, {
                format: DATE_TIME_FORMAT.GENERAL,
              })}
            </Typography>
          </Stack>
          <Stack spacing={{ xs: 0.5 }}>
            <Typography variant="Web_Label_18" color={theme.palette.grey3.dark}>
              End Date
            </Typography>
            <Typography variant="Web_Label_18">
              {formatDate(data.end_datetime, {
                format: DATE_TIME_FORMAT.GENERAL,
              })}
            </Typography>
          </Stack>
          <Stack spacing={{ xs: 0.5 }}>
            <Typography variant="Web_Label_18" color={theme.palette.grey3.dark}>
              Duration
            </Typography>
            <Typography variant="Web_Label_18">
              {pluralString('hour', data.total_hours)}
            </Typography>
          </Stack>
        </Stack>
        <Stack spacing={0.5}>
          <Typography variant="Web_Label_18" color={theme.palette.grey3.dark}>
            Address
          </Typography>
          <Typography variant="Web_Label_18" textTransform={'capitalize'}>
            {getAddressInformation()}
          </Typography>
        </Stack>
        <Stack spacing={0.5}>
          <Typography variant="Web_Label_18" color={theme.palette.grey3.dark}>
            Sitter
          </Typography>
          <Typography variant="Web_Label_18">
            {data.Sitter.User.first_name}
          </Typography>
        </Stack>
        <Divider />
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Stack spacing={0.5}>
            <Typography variant="Web_Label_18" color={theme.palette.grey3.dark}>
              Total
            </Typography>
            <Typography
              variant="Small_Profile_16"
              color={theme.palette.primary.main}
            >
              {formatPrice(data.total)}
            </Typography>
          </Stack>
          {showButtons && (
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Button
                variant="text"
                onClick={(e) =>
                  onClickButton(e, setModalAction({ type: ACTION_TYPE.CANCEL }))
                }
              >
                Cancel Booking
              </Button>
              {!!data.JobExtensions.length ? (
                <Button
                  variant="contained"
                  onClick={(e) =>
                    onClickButton(
                      e,
                      setModalAction({ type: ACTION_TYPE.EXTEND }),
                    )
                  }
                >
                  View Change
                </Button>
              ) : data.is_paid ? (
                <Button
                  variant="outlined"
                  onClick={(e) =>
                    onClickButton(e, navigate(APP_ROUTES.JOB.EDIT.to(data.id)))
                  }
                >
                  Edit booking
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={(e) => onClickButton(e, handlePaymentJob)}
                >
                  {mapButtonTitle[data.status as JOB_STATUS]}
                </Button>
              )}
            </Stack>
          )}
        </Stack>
      </StyledJobItem>
      {modalAction?.type === ACTION_TYPE.CANCEL && (
        <PopupConfirm
          typeIcon={TYPE_ICON.HELP}
          title="Cancel Booking"
          textButton2="Back"
          content={
            <Stack spacing={1.5}>
              <Typography variant="Web_Label_14">
                Are you sure you want to cancel this booking?
              </Typography>
              {isBefore24h(data.start_datetime) && (
                <Typography variant="Web_Label_14">
                  A&nbsp;
                  <Typography variant="Web_Label_14" fontWeight={700}>
                    1 hour cancellation fee
                  </Typography>
                  &nbsp;will be applied for sits that are cancelled 24 hours
                  before the sit.
                </Typography>
              )}
            </Stack>
          }
          onSubmit={onSubmitCallback}
          handleClose={onCloseModalAction}
        />
      )}
      {modalAction?.type === ACTION_TYPE.CANCELLED && (
        <PopupConfirm
          typeIcon={TYPE_ICON.CHECK}
          title={SYS_MESS.SUCCESS.BOOKING_CANCELED}
          content={
            <Stack>
              <Typography variant="Web_Label_14"></Typography>
              <Typography variant="Web_Label_14">
                Your booking has been cancelled.
                <Typography component="span" variant="Web_Label_14">
                  &nbsp;${data.total - data.salary_per_hour}
                </Typography>
                &nbsp;credits will be refunded to your account
              </Typography>
            </Stack>
          }
          textButton="Back to Bookings"
          handleClose={onCloseModalAction}
        />
      )}
      {modalAction?.type === ACTION_TYPE.EXTEND && (
        <AcceptExtensionReductionModal
          dataJob={data}
          _onClose={onCloseModalAction}
        />
      )}
    </>
  );
});

export default JobItem;
