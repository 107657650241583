export const APP_ROUTES = {
  path: '/*',
  to: '/',
  AUTH: {
    path: 'account/*',
    to: '/account',
    LOGIN: {
      path: 'login',
      to: '/account/login',
    },
    SIGN_UP: {
      path: 'sign-up',
      to: '/account/sign-up',
    },
    VERIFY: {
      path: 'verify',
      to: '/account/verify',
    },
    FORGOT_PASSWORD: {
      path: 'forgot-password',
      to: '/account/forgot-password',
    },
    RESET_PASSWORD: {
      path: 'reset-password',
      to: '/account/reset-password',
    },
    CHECK_CODE: {
      path: 'check-code',
      to: '/account/check-code',
    },
    VERIFIED: {
      path: 'verified',
      to: '/account/verified',
    },
  },
  HOW_IT_WORKS: {
    path: 'how-it-works',
    to: '/how-it-works',
  },
  CREATE_REQUEST: {
    path: 'create-request',
    to: '/create-request',
  },
  OVERVIEW: {
    path: 'overview/*',
    to: '/overview',
    SITTER_DETAIL: {
      path: 'detail/:id',
      to: (id: string | number) => `/sitter/detail/${id}`,
    },
  },
  CREDITS: {
    path: 'credits/*',
    to: '/credits',
    OVERVIEW: {
      path: 'overview',
      to: '/credits/overview',
    },
    BUY_PACKAGE: {
      path: 'buy-package',
      to: '/credits/buy-package',
    },
    PAYMENT: {
      path: 'payment',
      to: '/credits/payment',
    },
  },
  JOB: {
    path: 'jobs/*',
    to: '/jobs',
    DETAIL: {
      path: 'detail/:id',
      to: (id: string | number) => `/jobs/detail/${id}`,
    },
    EDIT: {
      path: 'edit/:id',
      to: (id: string | number) => `/jobs/edit/${id}`,
    },
    PAYMENT: {
      path: 'payment',
      to: '/jobs/payment',
    },
  },
  SITTER_DETAIL: {
    path: 'sitter-detail/:id',
    to: (id: string | number) => `/sitter-detail/${id}`,
  },
  PROFILE: {
    path: 'profile',
    to: '/profile',
  },
  NOT_FOUND: {
    path: '404',
    to: '/404',
  },
  NOTIFICATIONS: {
    path: 'notifications',
    to: '/notifications',
  },
};
