import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack } from '@mui/material';
import { InputField, SelectField } from 'components';
import ModalUI from 'components/common/ModalUI';
import { NotifyService } from 'config';
import yup from 'config/yup.custom';
import { SYS_MESS } from 'constants/systemMessage';
import { ageRangeOptions } from 'features/create-request/helpers';
import { useDebounce } from 'hooks';
import { OPTION_ITEM } from 'models';
import { CHILDREN_LIST_RESPONSE } from 'models/parent/children';
import { FormEvent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hook';
import {
  addChildrenAction,
  getChildrenListAction,
  updateChildrenAction,
} from 'store/reducers/parent/actionTypes';

interface IAddEditChildrenForm {
  full_name: string;
  age_range: OPTION_ITEM<number>;
}

interface PopupAddEditChildrenProps {
  openPop: boolean;
  onClose: () => void;
  isEdit: boolean;
  selectedChildren?: CHILDREN_LIST_RESPONSE;
}

const schema = yup
  .object({
    full_name: yup.string().required(SYS_MESS.ERROR.NAME_OF_CHILD_REQUIRED),
    age_range: yup.mixed().required(SYS_MESS.ERROR.AGE_REQUIRED),
  })
  .required();

export const PopupAddEditChildren = ({
  openPop,
  onClose,
  isEdit,
  selectedChildren,
}: PopupAddEditChildrenProps) => {
  const dispatch = useAppDispatch();
  const { globalLoading } = useAppSelector((state) => state.global);

  const { handleSubmit, control, reset } = useForm<IAddEditChildrenForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    reset({
      full_name: '',
      age_range: null,
    });
    onClose();
  };

  const onSubmit = useDebounce(async (data: IAddEditChildrenForm) => {
    if (globalLoading) return;
    const dispatchAction = isEdit ? updateChildrenAction : addChildrenAction;
    const _params = {
      full_name: data.full_name,
      age_range: data.age_range.id,
      id: isEdit ? selectedChildren.id : undefined,
    };

    dispatch(
      dispatchAction({
        data: {
          ..._params,
        },
        onSuccess: () => {
          handleClose();
          dispatch(getChildrenListAction());
        },
        onFailed: (e) => {
          NotifyService.error(e);
        },
      }),
    );
  }, 500);

  useEffect(() => {
    if (isEdit) {
      reset({
        full_name: selectedChildren.full_name,
        age_range: ageRangeOptions.find(
          (range) => range.id === selectedChildren.age_range,
        ),
      });
    }
  }, [selectedChildren, isEdit, reset]);

  return (
    <ModalUI
      open={openPop}
      onClose={handleClose}
      title={isEdit ? 'Edit Children' : 'Add Children'}
      PaperProps={{ sx: { maxWidth: '600px', width: '100%' } }}
    >
      <Stack
        component="form"
        spacing={2}
        onSubmit={(event: FormEvent<HTMLFormElement>) => {
          event.stopPropagation();
          handleSubmit(onSubmit)(event);
        }}
      >
        <Stack spacing={1} direction={'row'}>
          <InputField
            control={control}
            name={'full_name'}
            placeholder="What is your child’s name?"
            label="Name of Child"
          />
          <SelectField
            control={control}
            name={'age_range'}
            placeholder="Select one"
            options={ageRangeOptions}
            label="Age"
          />
        </Stack>
        <Button variant="contained" type="submit" sx={{ width: '100%' }}>
          Save
        </Button>
      </Stack>
    </ModalUI>
  );
};

export default PopupAddEditChildren;
