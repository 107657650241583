import { getNewToken, NotifyService } from 'config';
import { STORAGE_KEY } from 'constants/global';
import { SYS_MESS } from 'constants/systemMessage';
import { CONNECTION_WSS_STATUS, EVENT_WSS } from 'models';
import { PURCHASED_PACKAGE_FROM } from 'models/credits';
import { WEB_HOOK_PAYMENT_RESPONSE } from 'models/payments';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from 'routers/routes';
import { io, Socket } from 'socket.io-client';
import { useAppDispatch } from 'store/hook';
import { setOpenPaymentModal } from 'store/reducers/payments';
import { updateStepTrial } from 'store/reducers/trial-request';
import { removeLocalStorage } from 'utils';
import useQueryString from './useQueryString';

type IConnectWebSocketInput = {
  token: string;
  paymentIntentId: string;
  // paymentIntentId to know which parent are making payments using the PAY_NOW method
};

type IConnectWebSocketOutput = {
  socket?: Socket;
  handleReconnectConnection?: () => void;
};

/**
 * Custom hook to handle connect wss and integration with socket.
 * Custom hook will return some functions & data real time.
 */

let socket: Socket | null = null;
export const useConnectWebSocket = ({
  token: currentToken,
  paymentIntentId,
}: IConnectWebSocketInput): IConnectWebSocketOutput => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const query = useQueryString();

  const registrationSocket = (registrationToken: string) => {
    if (!registrationToken) return null;
    const _socket = io(process.env.REACT_APP_SOCKET_URL, {
      // forceNew: true,
      auth: {
        token: `Bearer ${registrationToken}`,
      },
      extraHeaders: {
        Authorization: `Bearer ${registrationToken}`,
      },
      transports: ['websocket'],
      autoConnect: false,
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 2000,
      reconnectionDelayMax: 5000,
    });
    return _socket;
  };

  socket = registrationSocket(currentToken);

  // connected
  const handleConnectedConnection = () => {
    console.log(`Connected status:${CONNECTION_WSS_STATUS.CONNECTED}`);
  };

  // reconnect
  const handleReconnectConnection = async () => {
    const newToken = await getNewToken();
    socket = registrationSocket(newToken);
    socket.connect();
  };

  // disconnect
  const handleDisconnectConnection = () => {
    console.log(
      `Connection status: ${CONNECTION_WSS_STATUS.DISCONNECT}`,
      'handleDisconnectConnection',
    );
    socket.disconnect();
  };

  // close
  const handleCloseConnection = () => {
    console.log(
      `Connection status: ${CONNECTION_WSS_STATUS.CLOSE}`,
      'handleCloseConnection',
    );
    socket.disconnect();
  };

  // catch error
  const handleConnectionError = async (err: Error) => {
    console.error(
      `Connection status: ${CONNECTION_WSS_STATUS.CONNECT_ERROR}`,
      err,
      'handleConnectionError',
    );
    console.log(currentToken, 'currentToken');
  };

  const handleListenEvent = (
    data: WEB_HOOK_PAYMENT_RESPONSE,
    event: EVENT_WSS,
  ) => {
    console.log(
      data.payment_intent_id,
      'data.payment_intent_id',
      paymentIntentId,
      'paymentIntentId',
      data.payment_intent_id.includes(paymentIntentId),
    );

    if (!data.payment_intent_id.includes(paymentIntentId)) return;

    NotifyService.success(SYS_MESS.SUCCESS.PAYMENT);
    dispatch(setOpenPaymentModal(false));

    switch (event) {
      case EVENT_WSS.PAYMENT_SEARCH_FEE_SUCCESS:
        removeLocalStorage(STORAGE_KEY.QR_CODE_SEARCH_FEE);
        dispatch(updateStepTrial(4));
        break;
      case EVENT_WSS.PAYMENT_TRIAL_JOB_SUCCESS:
        removeLocalStorage(STORAGE_KEY.QR_CODE_TRIAL_JOB);
        navigate(-1);
        break;
      case EVENT_WSS.PAYMENT_JOB_BY_TOP_UP_SUCCESS:
        removeLocalStorage(STORAGE_KEY.QR_CODE_TOP_UP);
        navigate(-1);
        break;
      case EVENT_WSS.PACKAGE_PURCHASE_SUCCESS:
        if (query.type === PURCHASED_PACKAGE_FROM.JOB) {
          navigate(-1);
        } else {
          navigate(APP_ROUTES.CREDITS.OVERVIEW.to);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!currentToken || !socket) return;

    const connectSocket = () => {
      if (socket && !socket.connected) {
        socket.connect();
      }
    };

    socket.on(CONNECTION_WSS_STATUS.CONNECTED, handleConnectedConnection);
    socket.on(CONNECTION_WSS_STATUS.CONNECT_ERROR, handleConnectionError);
    socket.on(CONNECTION_WSS_STATUS.DISCONNECT, handleDisconnectConnection);
    socket.on(CONNECTION_WSS_STATUS.CLOSE, handleCloseConnection);

    // listen for webhook events from socket to handle some actions
    socket.on(
      EVENT_WSS.PAYMENT_SEARCH_FEE_SUCCESS,
      (data: WEB_HOOK_PAYMENT_RESPONSE) =>
        handleListenEvent(data, EVENT_WSS.PAYMENT_SEARCH_FEE_SUCCESS),
    );
    socket.on(
      EVENT_WSS.PAYMENT_TRIAL_JOB_SUCCESS,
      (data: WEB_HOOK_PAYMENT_RESPONSE) =>
        handleListenEvent(data, EVENT_WSS.PAYMENT_TRIAL_JOB_SUCCESS),
    );
    socket.on(
      EVENT_WSS.PAYMENT_JOB_BY_TOP_UP_SUCCESS,
      (data: WEB_HOOK_PAYMENT_RESPONSE) =>
        handleListenEvent(data, EVENT_WSS.PAYMENT_JOB_BY_TOP_UP_SUCCESS),
    );
    socket.on(
      EVENT_WSS.PACKAGE_PURCHASE_SUCCESS,
      (data: WEB_HOOK_PAYMENT_RESPONSE) =>
        handleListenEvent(data, EVENT_WSS.PACKAGE_PURCHASE_SUCCESS),
    );

    connectSocket();

    if (socket) {
      return () => {
        socket.disconnect();
        socket.removeAllListeners();
      };
    }

    // eslint-disable-next-line
  }, [currentToken]);

  // eslint-disable-next-line
  const _window: any = window;
  _window['socket'] = socket;

  return {
    socket,
    handleReconnectConnection,
  };
};
