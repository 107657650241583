import { PayloadActionData } from 'models';
import { GET_NOTIFICATION_PARAMS } from 'models/notifications';

export const GET_NOTIFICATION_LIST_REQUEST = 'GET_NOTIFICATION_LIST_REQUEST';

export const MARK_AS_READ_REQUEST = 'MARK_AS_READ_REQUEST';

export const MARK_ALL_REQUEST = 'MARK_ALL_REQUEST';

export const getNotificationListAction = (
  payload: PayloadActionData<GET_NOTIFICATION_PARAMS>,
) => ({
  type: GET_NOTIFICATION_LIST_REQUEST,
  payload,
});

export const markAsReadAction = (
  payload: PayloadActionData<{ id: string }>,
) => ({
  type: MARK_AS_READ_REQUEST,
  payload,
});

export const markAllAction = (payload: PayloadActionData) => ({
  type: MARK_ALL_REQUEST,
  payload,
});
