import { Avatar, Button, Divider, Stack, Typography } from '@mui/material';
import { ArrowLeftIcon } from 'assets';
import { PopupConfirm, TYPE_ICON } from 'components';
import { DATE_TIME_FORMAT } from 'constants/date';
import { SYS_MESS } from 'constants/systemMessage';
import { AcceptExtensionReductionModal } from 'features/bookings/components';
import { ageRangeOptions } from 'features/create-request/helpers';
import { formatDate } from 'helpers';
import { ACTION_TYPE, JOB_STATUS, MODAL_ACTION } from 'models';
import { useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { APP_ROUTES } from 'routers/routes';
import { useAppDispatch, useAppSelector } from 'store/hook';
import {
  cancelJobAction,
  getJobDetailAction,
} from 'store/reducers/job/actionTypes';
import { updateACRequestSitterStatus } from 'store/reducers/overview';
import { updateDataPaymentJob } from 'store/reducers/payments';
import { theme } from 'theme';
import { isBefore24h, pluralString, toCapitalize } from 'utils';
import {
  mapBookingColor,
  mapBookingTitle,
  mapButtonTitle,
} from '../../helpers';
import {
  StyledBackgroundOverlay,
  StyledBookingDetailWrapper,
  StyledBoxChildrenWrapper,
  StyledContentWrapper,
  StyledJobWrapper,
  StyledSitterWrapper,
} from './styles';

function JobDetail() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { jobDetail } = useAppSelector((state) => state.jobs);
  const [modalAction, setModalAction] = useState<MODAL_ACTION<ACTION_TYPE>>();

  const { id } = useParams();

  const showButtons = ![
    JOB_STATUS.CANCELLED,
    JOB_STATUS.STARTED,
    JOB_STATUS.PENDING_COMPLETED,
    JOB_STATUS.COMPLETED,
  ].includes(jobDetail?.status);

  const handlePaymentJob = () => {
    dispatch(
      updateDataPaymentJob({
        id: jobDetail.id,
        total: jobDetail.total,
        isTrial: jobDetail.is_trial_job,
      }),
    );
    navigate(APP_ROUTES.JOB.PAYMENT.to);
  };

  const onSubmitCallback = () => {
    dispatch(
      cancelJobAction({
        data: { id: jobDetail.id },
        onSuccess: () => {
          setModalAction({ type: ACTION_TYPE.CANCELLED });
        },
      }),
    );
  };

  const onCloseModalAction = () => {
    setModalAction(undefined);
  };

  const onClickButton = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    callback: any,
  ) => {
    e.preventDefault();
    callback?.();
  };

  const getAddressInformation = () => {
    const { street, unit_number, block_number, postal_code } =
      jobDetail.address;
    return `${unit_number}, ${block_number} ${street}, Singapore ${postal_code}`;
  };

  useEffect(() => {
    dispatch(
      getJobDetailAction({
        data: { id },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (!jobDetail) return;
  return (
    <StyledBookingDetailWrapper spacing={2}>
      <StyledBackgroundOverlay>
        <NavLink to={APP_ROUTES.JOB.to}>
          <ArrowLeftIcon sx={{ color: theme.palette.white.light }} />
          <Typography lineHeight="21px" variant="Web_Label_16">
            Back to Bookings
          </Typography>
        </NavLink>
      </StyledBackgroundOverlay>
      <StyledContentWrapper gap={2}>
        <StyledJobWrapper spacing={2}>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            justifyContent={{ xs: 'start', md: 'space-between' }}
            spacing={{ xs: 4, md: 0 }}
          >
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              spacing={{ xs: 1, md: 0.5 }}
            >
              <Typography variant="Web_Title_18">{jobDetail.number}</Typography>
            </Stack>
            <Typography
              variant="Web_Label_16"
              color={mapBookingColor[jobDetail.status as JOB_STATUS]}
            >
              {mapBookingTitle[jobDetail.status as JOB_STATUS]}
            </Typography>
          </Stack>
          <Divider />
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={{ xs: 4, md: 14 }}
          >
            <Stack spacing={{ xs: 0.5 }}>
              <Typography
                variant="Web_Label_16"
                color={theme.palette.grey3.dark}
              >
                Start Date
              </Typography>
              <Typography variant="Web_Label_16">
                {formatDate(jobDetail.start_datetime, {
                  format: DATE_TIME_FORMAT.GENERAL,
                })}
              </Typography>
            </Stack>
            <Stack spacing={{ xs: 0.5 }}>
              <Typography
                variant="Web_Label_16"
                color={theme.palette.grey3.dark}
              >
                End Date
              </Typography>
              <Typography variant="Web_Label_16">
                {formatDate(jobDetail.end_datetime, {
                  format: DATE_TIME_FORMAT.GENERAL,
                })}
              </Typography>
            </Stack>
            <Stack spacing={{ xs: 0.5 }}>
              <Typography
                variant="Web_Label_16"
                color={theme.palette.grey3.dark}
              >
                Duration
              </Typography>
              <Typography variant="Web_Label_16">
                {pluralString('hour', jobDetail.total_hours)}
              </Typography>
            </Stack>
          </Stack>
          <Stack spacing={0.5}>
            <Typography variant="Web_Label_16" color={theme.palette.grey3.dark}>
              Address
            </Typography>
            <Typography variant="Web_Label_16" textTransform={'capitalize'}>
              {getAddressInformation()}
            </Typography>
          </Stack>
          <StyledBoxChildrenWrapper>
            {jobDetail.children.map((item) => (
              <Stack
                key={item.id}
                direction={'row'}
                alignItems={'center'}
                spacing={1}
              >
                <Avatar className="avtChildren">
                  {item.full_name.charAt(0)}
                </Avatar>
                <Stack>
                  <Typography className="nameChildren">
                    {item.full_name}
                  </Typography>
                  <Typography className="ageChildren">
                    {
                      ageRangeOptions.find(
                        (range) => range.id === item.age_range,
                      )?.name
                    }
                  </Typography>
                </Stack>
              </Stack>
            ))}
          </StyledBoxChildrenWrapper>
          <Divider />
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            justifyContent={'space-between'}
            alignItems={'center'}
            spacing={{ xs: 0.5, md: 0 }}
          >
            <Typography variant="Web_Label_16" color={theme.palette.grey3.dark}>
              Created on:&nbsp;
              {formatDate(jobDetail.createdAt, {
                format: DATE_TIME_FORMAT.NORMAL_DATE,
              })}
            </Typography>
            {showButtons && (
              <Stack direction={'row'} spacing={1}>
                <Button
                  variant="text"
                  onClick={(e) =>
                    onClickButton(
                      e,
                      setModalAction({ type: ACTION_TYPE.CANCEL }),
                    )
                  }
                >
                  Cancel Booking
                </Button>
                {!!jobDetail.JobExtensions?.length ? (
                  <Button
                    variant="contained"
                    onClick={(e) =>
                      onClickButton(
                        e,
                        setModalAction({ type: ACTION_TYPE.EXTEND }),
                      )
                    }
                  >
                    View Change
                  </Button>
                ) : jobDetail.is_paid ? (
                  <Button
                    variant="outlined"
                    onClick={(e) =>
                      onClickButton(
                        e,
                        navigate(APP_ROUTES.JOB.EDIT.to(jobDetail.id)),
                      )
                    }
                  >
                    Edit booking
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={(e) => onClickButton(e, handlePaymentJob)}
                  >
                    {mapButtonTitle[jobDetail.status as JOB_STATUS]}
                  </Button>
                )}
              </Stack>
            )}
          </Stack>
        </StyledJobWrapper>
        <StyledSitterWrapper>
          <Typography className="sitterTitle">Sitter</Typography>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Stack direction={'row'} spacing={1.5} alignItems="center">
              <img
                src={jobDetail.Sitter.profile_picture}
                alt=""
                className="sitterPicture"
              />
              <Stack>
                <Typography className="sitterName">
                  {toCapitalize(jobDetail.Sitter.User.first_name) +
                    ' ' +
                    toCapitalize(jobDetail.Sitter.User.last_name)}
                </Typography>
                <Typography className="totalExp">
                  Total Exp on App: {jobDetail.total_hours} Hours
                </Typography>
              </Stack>
            </Stack>
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <Typography
                variant="Web_Label_14"
                color={theme.palette.red.light}
              >
                Accepted
              </Typography>
              <Button
                variant="contained"
                onClick={() => {
                  dispatch(updateACRequestSitterStatus(ACTION_TYPE.ACCEPTED));
                  navigate(APP_ROUTES.SITTER_DETAIL.to(jobDetail.sitter_id));
                }}
              >
                View
              </Button>
            </Stack>
          </Stack>
        </StyledSitterWrapper>
      </StyledContentWrapper>
      {modalAction?.type === ACTION_TYPE.CANCEL && (
        <PopupConfirm
          typeIcon={TYPE_ICON.HELP}
          title="Cancel Booking"
          content={
            <Stack spacing={1.5}>
              <Typography variant="Web_Label_14">
                Are you sure you want to cancel this booking?
              </Typography>
              {isBefore24h(jobDetail.start_datetime) && (
                <Typography variant="Web_Label_14">
                  A&nbsp;
                  <Typography variant="Web_Label_14" fontWeight={700}>
                    1 hour cancellation fee
                  </Typography>
                  &nbsp;will be applied for sits that are cancelled 24 hours
                  before the sit.
                </Typography>
              )}
            </Stack>
          }
          textButton2="Back"
          onSubmit={onSubmitCallback}
          handleClose={onCloseModalAction}
        />
      )}
      {modalAction?.type === ACTION_TYPE.CANCELLED && (
        <PopupConfirm
          typeIcon={TYPE_ICON.CHECK}
          title={SYS_MESS.SUCCESS.BOOKING_CANCELED}
          content={
            <>
              <Typography variant="Web_Label_14"></Typography>
              <Typography variant="Web_Label_14">
                Your booking has been cancelled.
                <Typography component="span" variant="Web_Label_14">
                  ${jobDetail.total - jobDetail.salary_per_hour}&nbsp;
                </Typography>
                credits will be refunded to your account
              </Typography>
            </>
          }
          textButton="Back to Bookings"
          handleClose={onCloseModalAction}
        />
      )}
      {modalAction?.type === ACTION_TYPE.EXTEND && (
        <AcceptExtensionReductionModal
          dataJob={jobDetail}
          _onClose={onCloseModalAction}
        />
      )}
    </StyledBookingDetailWrapper>
  );
}

export default JobDetail;
