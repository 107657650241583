import { Stack, Typography } from '@mui/material';
import { PaymentComponent } from 'components';
import PopupConfirm, { TYPE_ICON } from 'components/popup/PopupConfirm';
import { STORAGE_KEY } from 'constants/global';
import { SYS_MESS } from 'constants/systemMessage';
import {
  PAYMENT_MODAL_TYPE,
  PAYMENT_TYPE,
  PAY_JOB_PAYLOAD,
} from 'models/payments';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setOpenPaymentSuccess } from 'store/reducers/payments';
import {
  checkoutACJobAction,
  checkoutExtendJobAction,
  checkoutTrialJobAction,
  payExtendJobByTopUpAction,
  payForExtendJobAction,
  payForExtendTrialJobAction,
  payForNormalJobAction,
  payForTrialJobAction,
  payJobByTopUpAction,
} from 'store/reducers/payments/actionTypes';
import { formatPrice } from 'utils';

const PaymentJobs = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { dataPaymentJob, openPaymentSuccess, paymentMethodId } =
    useAppSelector((state) => state.payment);
  const { checkoutData } = useAppSelector((state) => state.payment);
  const { balance, total_amount, is_can_purchase_package } = checkoutData;
  const { id, isTrial, isExtend, total } = dataPaymentJob;

  const handleBackAction = () => {
    navigate(-1);
  };

  const checkoutJobAction = (isPayNow: boolean) => {
    const dispatchAction = isExtend
      ? checkoutExtendJobAction
      : isTrial
      ? checkoutTrialJobAction
      : checkoutACJobAction;

    dispatch(
      dispatchAction({
        data: {
          payment_method_type:
            isExtend || isTrial
              ? isPayNow
                ? PAYMENT_MODAL_TYPE.PAY_NOW
                : PAYMENT_MODAL_TYPE.CARD
              : undefined,
          job_id: id,
        },
        onFailed: () => {
          handleBackAction();
        },
      }),
    );
  };

  const getPaymentAction = () => {
    if (isTrial) {
      return isExtend ? payForExtendTrialJobAction : payForTrialJobAction;
    }
    if (balance < total_amount) {
      return is_can_purchase_package
        ? isExtend
          ? payForExtendJobAction
          : payForNormalJobAction
        : isExtend
        ? payExtendJobByTopUpAction
        : payJobByTopUpAction;
    }
    return isExtend ? payForExtendJobAction : payForNormalJobAction;
  };

  const paymentCallback = (isPayNow: boolean, callback?: Function) => {
    const isNotEnoughBalance =
      balance < total_amount && !is_can_purchase_package;

    const _params =
      isTrial || isNotEnoughBalance
        ? {
            payment_method_id: !isPayNow ? paymentMethodId : undefined,
            payment_method_type: isPayNow
              ? PAYMENT_MODAL_TYPE.PAY_NOW
              : PAYMENT_MODAL_TYPE.CARD,
            job_id: dataPaymentJob.id,
          }
        : { job_id: dataPaymentJob.id };

    dispatch(
      getPaymentAction()({
        data: _params as PAY_JOB_PAYLOAD,
        onSuccess: (qrCode: string) => {
          if (isPayNow && (isTrial || isNotEnoughBalance)) {
            callback?.(qrCode);
            return;
          }
          dispatch(setOpenPaymentSuccess(true));
        },
        onFailed: () => {},
        onFinally: () => {},
      }),
    );
  };

  return (
    <Stack spacing={8}>
      <Stack spacing={1} alignItems="center">
        <Typography variant="Web_Title_22">Payment</Typography>
        <Typography variant="Web_Label_18">
          {isTrial ? 'Book a Trial' : 'Buy a Plan'}
        </Typography>
      </Stack>
      <React.Fragment>
        <PaymentComponent
          moreFeeChildren={
            <Stack>
              <Typography variant="Components_Column_content" color={'#6d6d6d'}>
                {isTrial ? 'Trial Fees' : 'Job Fee'}
              </Typography>
              <Typography variant="Components_Column_content" color={'#6d6d6d'}>
                {formatPrice(total)}
              </Typography>
            </Stack>
          }
          storageKey={
            isTrial
              ? STORAGE_KEY.QR_CODE_TRIAL_JOB
              : balance < total_amount && !is_can_purchase_package
              ? STORAGE_KEY.QR_CODE_TOP_UP
              : undefined
          }
          paymentType={
            isTrial
              ? PAYMENT_TYPE.TRIAL_JOB
              : balance >= total_amount
              ? PAYMENT_TYPE.AC_JOB_ENOUGH_BALANCE
              : PAYMENT_TYPE.AC_JOB_NOT_ENOUGH_BALANCE
          }
          checkoutAction={checkoutJobAction}
          handleBackAction={handleBackAction}
          paymentCallback={paymentCallback}
        />
      </React.Fragment>
      {openPaymentSuccess && (
        <PopupConfirm
          typeIcon={TYPE_ICON.CHECK}
          title={SYS_MESS.SUCCESS.PAYMENT}
          content={
            <Typography variant="Web_Label_14">
              Your payment has been successfully made
            </Typography>
          }
          textButton="Back to Bookings"
          onSubmit={handleBackAction}
          handleBack={() => navigate(-1)}
          handleClose={() => {
            dispatch(setOpenPaymentSuccess(false));
          }}
          justCloseWithBtn
        />
      )}
    </Stack>
  );
};

export default PaymentJobs;
