import { METADATA, PARAMETERS } from './common';

/* =========== ENUM ============ */
export enum PERMISSION_TYPE {
  GRANTED = 'granted',
  DENIED = 'denied',
}

export enum EVENT_FIREBASE_PARENT {
  AUTO_PAYMENT_FOR_JOB = 'FOR_PARENT_PAYMENT_SUCCESSFUL_FOR_AC_JOB ',
  ADD_SITTER_BY_ADMIN = 'FOR_PARENT_SITTER_ADDED_TO_AC_BASE_REQUEST',
}

/* =========== INTERFACE ============ */
export interface CREATE_DEVICE_TOKEN_PAYLOAD {
  fcm_token: string;
  uuid?: string;
}

export interface DELETE_DEVICE_TOKEN_PAYLOAD {
  fcm_token: string;
}

export interface NOTIFICATION_DATA {
  metadata: METADATA;
  records: NOTIFICATION_ITEM[];
}

export interface NOTIFICATION_ITEM {
  id: string;
  type: string;
  parent_id: string;
  sitter_id: any;
  title: string;
  body: string;
  objectTypeId: string;
  isRead: boolean;
  metadata?: {
    job_id?: string;
  };
  createdAt: string;
  updatedAt: string;
}

export interface GET_NOTIFICATION_PARAMS extends PARAMETERS {}
