export const splitNumber = (value: string) => {
  const country_codes = [
    '+91',
    '+1',
    '+44',
    '+93',
    '+355',
    '+213',
    '+376',
    '+244',
    '+54',
    '+374',
    '+297',
    '+61',
    '+43',
    '+994',
    '+973',
    '+880',
    '+375',
    '+32',
    '+501',
    '+229',
    '+975',
    '+591',
    '+387',
    '+267',
    '+55',
    '+246',
    '+673',
    '+359',
    '+226',
    '+257',
    '+855',
    '+237',
    '+238',
    '+599',
    '+236',
    '+235',
    '+56',
    '+86',
    '+57',
    '+269',
    '+243',
    '+242',
    '+682',
    '+506',
    '+225',
    '+385',
    '+53',
    '+357',
    '+420',
    '+45',
    '+253',
    '+593',
    '+20',
    '+503',
    '+240',
    '+291',
    '+372',
    '+251',
    '+500',
    '+298',
    '+679',
    '+358',
    '+33',
    '+594',
    '+689',
    '+241',
    '+220',
    '+995',
    '+49',
    '+233',
    '+350',
    '+30',
    '+299',
    '+590',
    '+502',
    '+224',
    '+245',
    '+592',
    '+509',
    '+504',
    '+852',
    '+36',
    '+354',
    '+62',
    '+98',
    '+964',
    '+353',
    '+972',
    '+39',
    '+81',
    '+962',
    '+7',
    '+254',
    '+686',
    '+383',
    '+965',
    '+996',
    '+856',
    '+371',
    '+961',
    '+266',
    '+231',
    '+218',
    '+423',
    '+370',
    '+352',
    '+853',
    '+389',
    '+261',
    '+265',
    '+60',
    '+960',
    '+223',
    '+356',
    '+692',
    '+596',
    '+222',
    '+230',
    '+262',
    '+52',
    '+691',
    '+373',
    '+377',
    '+976',
    '+382',
    '+212',
    '+258',
    '+95',
    '+264',
    '+674',
    '+977',
    '+31',
    '+687',
    '+64',
    '+505',
    '+227',
    '+234',
    '+683',
    '+672',
    '+850',
    '+47',
    '+968',
    '+92',
    '+680',
    '+970',
    '+507',
    '+675',
    '+595',
    '+51',
    '+63',
    '+48',
    '+351',
    '+974',
    '+40',
    '+250',
    '+290',
    '+508',
    '+685',
    '+378',
    '+239',
    '+966',
    '+221',
    '+381',
    '+248',
    '+232',
    '+65',
    '+421',
    '+386',
    '+677',
    '+252',
    '+27',
    '+82',
    '+211',
    '+34',
    '+94',
    '+249',
    '+597',
    '+268',
    '+46',
    '+41',
    '+963',
    '+886',
    '+992',
    '+255',
    '+66',
    '+670',
    '+228',
    '+690',
    '+676',
    '+216',
    '+90',
    '+993',
    '+688',
    '+256',
    '+380',
    '+971',
    '+598',
    '+998',
    '+678',
    '+58',
    '+84',
    '+681',
    '+967',
    '+260',
    '+263',
    '+358',
  ];
  for (let i = 0; i < country_codes.length; i += 1) {
    if (value.includes(country_codes[i])) {
      const code_size: number = country_codes[i].length;
      return {
        code: value.substring(0, code_size),
        number: value.substring(code_size),
      };
    }
  }
  return null;
};

export const formatPriceToSGD = (price: number | string) =>
  `S${price.toLocaleString('en-SG', {
    style: 'currency',
    currency: 'SGD',
    minimumFractionDigits: 2,
  })}`;

export const printPrice = (number: number) => {
  if ((number ?? null) === null) return '';
  if (number < 0) {
    return (
      number.toString().charAt(0) + '$' + number.toFixed(2).toString().slice(1)
    );
  } else {
    return '$' + number.toFixed(2);
  }
};

export const sleep = (ms: number) => {
  return new Promise((resole) => setTimeout(resole, ms));
};

export const truncateString = (input: string, numberOfCharacters: number) =>
  input.length > numberOfCharacters
    ? `${input.substring(0, numberOfCharacters)}...`
    : input;

export const pluralString = (input: string, count: number) => {
  return count > 1 ? `${count} ${input}s` : `${count} ${input}`;
};

export const toCapitalize = (str: string) => {
  if (!str) return '';
  const strToLowerCase = str.toLocaleLowerCase();
  return strToLowerCase.charAt(0).toUpperCase() + strToLowerCase.slice(1);
};

export const isUndefined = (value: string) => {
  return !value || value === 'undefined';
};

// Main function to format the price
export const formatPrice = (price: number | undefined): string => {
  // Handle undefined or invalid input
  if (price === undefined || isNaN(price)) return '$0.00';

  // Round a number to the nearest cent (two decimal places)
  const roundToNearestCent = (price: number): number =>
    Math.round(price * 100) / 100;

  // Format a number with two decimal places and add a dollar sign
  const formatWithTwoDecimals = (price: number): string =>
    `${price.toFixed(2)}`;

  // Round the price to the nearest cent and format
  return `$${formatWithTwoDecimals(roundToNearestCent(price))}`;
};
