import { DATE_TIME_FORMAT } from 'constants/date';
import moment, { Moment } from 'moment-timezone';

export const formatDate = (
  value: moment.MomentInput,
  options?: { format?: string; empty?: string; valFormat?: string },
) => {
  return value
    ? moment(value, options?.valFormat).format(
        options?.format || DATE_TIME_FORMAT.NORMAL_DATE,
      )
    : options?.empty || '';
};

export const convertToUtcTime = (date: Date) => {
  if (isNaN(date.getTime())) return null;

  const tzText = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (tzText === 'Asia/Singapore') {
    return date.toISOString();
  } else {
    function getTimeZoneNumber(date: Date, timeZone: string) {
      const options = { timeZone, timeZoneName: 'short' } as any;
      const dateTimeFormat = new Intl.DateTimeFormat([], options);

      const [{ value: tzNumber }] = dateTimeFormat
        .formatToParts(date)
        .filter((part) => part.type === 'timeZoneName');
      return tzNumber.substring(4);
    }

    const tzSGNumber = 8;
    const differenceTimezoneCountInMilSeconds =
      (tzSGNumber - Number(getTimeZoneNumber(date, tzText))) * 60 * 60 * 1000;

    const newDate = new Date(
      date.getTime() - differenceTimezoneCountInMilSeconds,
    );
    return newDate.toISOString();
  }
};

export const convertToLocalTime = (date: Date | string) => {
  let newDate: Date | string;
  if (typeof date === 'string') {
    newDate = new Date(date);
  } else newDate = date;

  if (isNaN(newDate.getTime())) return null;
  const localOffset = newDate.getTimezoneOffset();
  return new Date(
    newDate.getTime() + localOffset * 60 * 1000 + 8 * 60 * 60 * 1000,
  );
};

export const mergeDateTime = (date: moment.Moment, time: Date) => {
  const timeStr = time.toLocaleTimeString('en-GB', {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });

  // const formatDateTimeSGT = `${date?.format(
  //   'YYYY/MM/DD',
  // )} ${timeStr} GMT+0800 (Singapore Standard Time)`;

  const concatDateTime = `${date?.format('YYYY/MM/DD')} ${timeStr} `;
  return new Date(concatDateTime);
};

export enum COMPARE_DATE {
  IS_SAME = 'IS_SAME',
  IS_BEFORE = 'IS_BEFORE',
  IS_AFTER = 'IS_AFTER',
}

export const compareDateTime = (d1: Date, d2: Date) => {
  const time1 = d1.getTime();
  const time2 = d2.getTime();

  switch (true) {
    case time1 < time2:
      return COMPARE_DATE.IS_BEFORE;
    case time1 > time2:
      return COMPARE_DATE.IS_AFTER;
    default:
      return COMPARE_DATE.IS_SAME;
  }
};

export const compareDateOnly = (d1: Moment, d2: Moment) => {
  const date1 = formatDate(d1, {
    format: DATE_TIME_FORMAT.NORMAL_DATE,
  });
  const date2 = formatDate(d2, {
    format: DATE_TIME_FORMAT.NORMAL_DATE,
  });

  switch (true) {
    case date1 < date2:
      return COMPARE_DATE.IS_BEFORE;
    case date1 > date2:
      return COMPARE_DATE.IS_AFTER;
    default:
      return COMPARE_DATE.IS_SAME;
  }
};

export const getMilBetweenDate = (d1: Date, d2: Date) => {
  const time1 = d1.getTime();
  const time2 = d2.getTime();

  return time2 - time1;
};

export const isBefore24h = (dateTime: string) => {
  const oneDayMilSecond = 24 * 60 * 60 * 1000;
  const twentyFourHoursBeforeStartTime = new Date(
    new Date(dateTime).getTime() - oneDayMilSecond,
  );

  return new Date() > twentyFourHoursBeforeStartTime;
};

// Function checks if a given date string matches today's date (ignoring time)
export const checkEqualDate = (input: string) => {
  // Create new Date object with current date/time
  const todayDate = new Date();
  const inputDate = new Date(input);

  // Compare the two dates after resetting hours/minutes/seconds/milliseconds to 0
  // setHours(0,0,0,0) - resets time components to 0
  return inputDate.setHours(0, 0, 0, 0) === todayDate.setHours(0, 0, 0, 0);
};
