export enum ENUM_SECTION_NAME_CHECK_LIST {
  KID_OF_AGE = 'Experience with kids of age',
  SPECIAL_NEED = 'Experience with Special Needs?',
  CERTIFICATIONS = 'Certifications',
  LANGUAGES = 'What languages do you speak?',
  COMFORTABLE_PETS = 'Are you comfortable with pets?',
  PREFERRED_LOCATION = 'Preferred Sit Location',
  OVERNIGHT_SIT = 'Overnight Sits',
}

export interface SITTER_TYPE {
  sitter: SITTER;
  checkLists: CHECK_LIST_EXPERIENCE_SITTER[];
}

export interface SITTER {
  sitter_id: string;
  first_name: string;
  last_name: string;
  bio: string;
  profile_picture: any;
  short_video: string;
  salary_per_hour: number;
  rating: number;
  star_sitter_badge: any;
  address: ADDRESS;
  Badges: BADGE[];
}

export interface ADDRESS {
  id: string;
  street: string;
  unit_number: string;
  postal_code: string;
  country: string;
  block_number: string;
  region_id: string;
  Region: any;
}

export interface BADGE {
  id: string;
  title: string;
  icon: string;
  no_of_bookings_needed: any;
  no_of_hours_worked: any;
}

export interface CHECK_LIST_EXPERIENCE_SITTER {
  id: number;
  section_name: string;
  items: ITEM_CHECK_LIST[];
}

export interface ITEM_CHECK_LIST {
  id: string;
  name: string;
  type: string;
  is_checked: boolean;
  text?: string;
}

//review

export interface REVIEW_TYPE {
  sitter: SITTER_REVIEW_TYPE;
  rows: REVIEW_ITEM[];
  metadata: METADATA;
}

export interface SITTER_REVIEW_TYPE {
  sitter_id: string;
  hours_worked: any;
  rating: string;
  review_count: string;
  repeat_count: string;
}

export interface REVIEW_ITEM {
  id: string;
  rate: number;
  job_id?: string;
  job_number?: string;
  content: string;
  is_anonymous?: boolean;
  createdAt: string;
  parent: PARENT;
}

export interface PARENT {
  parent_id?: string;
  first_name?: string;
  last_name?: string;
  profile_picture?: string;
}

export interface METADATA {
  page: number;
  limit: number;
  page_count: number;
  total_pages: number;
  total_count: number;
}
