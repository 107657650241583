import { Avatar, Divider, Rating, Stack, Typography } from '@mui/material';
import { APP_IMG, StarIcon } from 'assets';
import { DATE_TIME_FORMAT } from 'constants/date';
import { formatDate } from 'helpers';
import { REVIEW_ITEM } from 'models/sitter';
import { theme } from 'theme';
import { StyledReviewItem } from './styles';

interface Props {
  review: REVIEW_ITEM;
}

const ReviewItem = ({ review }: Props) => {
  return (
    <StyledReviewItem direction="row" spacing={3}>
      <Avatar
        alt="avatar_parent"
        src={
          review?.is_anonymous || review?.parent.profile_picture === null
            ? APP_IMG.defaultAvatar
            : review?.parent.profile_picture
        }
        sx={{ width: 36, height: 36 }}
      />
      <Stack spacing={1}>
        <Rating
          name="read-only"
          value={review?.rate}
          readOnly
          icon={<StarIcon sx={{ color: '#FFCC00' }} />}
          emptyIcon={<StarIcon sx={{ color: '#E5E5EA' }} />}
        />
        {review?.rate === 5 && (
          <Typography
            fontWeight={700}
            variant="Web_Title_18"
            color={theme.palette.secondary.main}
          >
            Amazing sitter!
          </Typography>
        )}
        {review?.content && (
          <Typography variant="Web_paragraph">{review.content}</Typography>
        )}
        <Divider />
        <Typography sx={{ opacity: 0.6 }} variant="Small_Body_16">
          {`Posted on: ${formatDate(review?.createdAt, {
            format: DATE_TIME_FORMAT.NORMAL_DATE,
          })} by ${
            review?.is_anonymous
              ? 'Anonymous'
              : `${review?.parent.first_name} ${review?.parent.last_name}`
          }`}
        </Typography>
      </Stack>
    </StyledReviewItem>
  );
};

export default ReviewItem;
