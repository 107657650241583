import { Stack, Typography } from '@mui/material';
import { EmptyUI } from 'components';
import { STORAGE_KEY } from 'constants/global';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { getMyRequestAction } from 'store/reducers/overview/actionTypes';
import { getLocalStorage } from 'utils';
import { RequestOverview, SittersOverView } from './components';

const Overview = () => {
  const dispatch = useAppDispatch();
  const { baseRequest } = useAppSelector((state) => state.overview);

  useEffect(() => {
    if (!baseRequest) {
      dispatch(
        getMyRequestAction({
          data: { accessToken: getLocalStorage(STORAGE_KEY.ACCESS_TOKEN) },
        }),
      );
    }
  }, [dispatch, baseRequest]);

  if (!baseRequest)
    return <EmptyUI content="You don’t have any request yet." />;
  return (
    <Stack spacing={2}>
      <Stack spacing={0.5} mb={1}>
        <Typography variant="Web_Title_22">Overview</Typography>
        <Typography variant="Web_Label_18">Overview of your request</Typography>
      </Stack>
      <RequestOverview requestData={baseRequest} />
      <SittersOverView
        sitterOverviewData={baseRequest.ac_base_request_sitters}
        statusRequest={baseRequest.status}
      />
    </Stack>
  );
};

export default Overview;
