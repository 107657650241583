import { PayloadAction } from '@reduxjs/toolkit';
import { PayloadActionData } from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';
import { notificationsAPI } from 'services/notifications';
import { setGlobalLoading } from 'store/reducers/global';
import {
  MARK_ALL_REQUEST,
  MARK_AS_READ_REQUEST,
} from 'store/reducers/notifications/actionTypes';

function* markAsReadRequest(
  action: PayloadAction<PayloadActionData<{ id: string }>>,
) {
  try {
    yield call(notificationsAPI.markAsRead, action.payload.data.id);
    action.payload.onSuccess?.();
  } catch (error) {
    action.payload.onFailed?.(error);
  } finally {
    action.payload.onFinally?.();
  }
}

function* markAllAsReadRequest(action: PayloadAction<PayloadActionData>) {
  try {
    yield put(setGlobalLoading(true));
    yield call(notificationsAPI.markReadAll);
    action.payload.onSuccess?.();
  } catch (error) {
    yield put(setGlobalLoading(false));
    action.payload.onFailed?.(error);
  } finally {
    yield put(setGlobalLoading(false));
    action.payload.onFinally?.();
  }
}

function* markReadNotification() {
  yield takeLatest(MARK_AS_READ_REQUEST, markAsReadRequest);
  yield takeLatest(MARK_ALL_REQUEST, markAllAsReadRequest);
}

export default markReadNotification;
