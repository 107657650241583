import { CircularProgress, Divider, MenuProps, Stack } from '@mui/material';
import EmptyUI from 'components/common/EmptyUI';
import NotifyItem from 'components/common/NotifyItem';
import { NOTIFICATION_DATA } from 'models/notifications';
import { MouseEvent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from 'routers/routes';
import { NotifyPopupRoot, NotifyTitle, ViewAllButton } from './styles';

interface NotificationBellProps extends MenuProps {
  loadingPopup: boolean;
  notifications: NOTIFICATION_DATA;
  onRefreshData: () => void;
  _onClose: () => void;
  _onClick: (e: MouseEvent<HTMLDivElement>, t: 'notify') => void;
}

const NotifyPopup = ({
  loadingPopup,
  notifications,
  _onClick,
  _onClose,
  onRefreshData,
  ...rest
}: NotificationBellProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    onRefreshData();
    // eslint-disable-next-line
  }, []);

  const handleViewAll = () => {
    _onClose();
    navigate(APP_ROUTES.NOTIFICATIONS.to);
  };

  return (
    <NotifyPopupRoot {...rest} onClose={_onClose}>
      <NotifyTitle mb={3}>Notifications</NotifyTitle>
      {loadingPopup ? (
        <Stack alignItems={'center'} justifyItems={'center'}>
          <CircularProgress />
        </Stack>
      ) : (
        <>
          <Stack spacing={2} mt={1}>
            {notifications.records.length > 0 ? (
              notifications.records.map((item, index) => (
                <Stack spacing={2}>
                  <NotifyItem key={index} data={item} onClose={_onClose} />
                  {notifications.records.length - 1 !== index && <Divider />}
                </Stack>
              ))
            ) : (
              <EmptyUI content="You don’t have any notifications yet." />
            )}
          </Stack>
          <ViewAllButton
            variant="contained"
            color="primary"
            onClick={handleViewAll}
          >
            View All
          </ViewAllButton>
        </>
      )}
    </NotifyPopupRoot>
  );
};

export default NotifyPopup;
