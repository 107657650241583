import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PAGINATION_TYPE } from 'models';
import {
  ADDRESS_LIST_RESPONSE,
  GET_ADDRESS_BY_POSTAL_CODE_RESPONSE,
  REGION_LIST_RESPONSE,
} from 'models/parent/address';
import { CHILDREN_LIST_RESPONSE } from 'models/parent/children';

export interface ParentType {
  childrenList: CHILDREN_LIST_RESPONSE[];
  addressList: ADDRESS_LIST_RESPONSE[];
  regions: PAGINATION_TYPE<REGION_LIST_RESPONSE[]>;
  addressByCode: GET_ADDRESS_BY_POSTAL_CODE_RESPONSE;
}

const initialState: ParentType = {
  childrenList: [],
  addressList: [],
  regions: {
    metadata: {
      page: 0,
      limit: 0,
      page_count: 0,
      total_pages: 0,
      total_count: 0,
    },
    rows: [],
  },
  addressByCode: null,
};

const parentSlice = createSlice({
  name: 'parent',
  initialState,
  reducers: {
    updateChildrenList(
      state: ParentType,
      action: PayloadAction<CHILDREN_LIST_RESPONSE[]>,
    ) {
      state.childrenList = action.payload;
    },
    updateAddressList(
      state: ParentType,
      action: PayloadAction<ADDRESS_LIST_RESPONSE[]>,
    ) {
      state.addressList = action.payload;
    },
    updateRegionList(
      state: ParentType,
      action: PayloadAction<PAGINATION_TYPE<REGION_LIST_RESPONSE[]>>,
    ) {
      state.regions = action.payload;
    },
    getAddressByPostalCode(
      state: ParentType,
      action: PayloadAction<GET_ADDRESS_BY_POSTAL_CODE_RESPONSE>,
    ) {
      state.addressByCode = action.payload;
    },
  },
});

// Actions
export const {
  updateChildrenList,
  updateAddressList,
  updateRegionList,
  getAddressByPostalCode,
} = parentSlice.actions;

export default parentSlice.reducer;
