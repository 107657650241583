import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { PayloadActionData, RESPONSE_TYPE } from 'models';
import {
  GET_NOTIFICATION_PARAMS,
  NOTIFICATION_DATA,
} from 'models/notifications';
import { call, takeLatest } from 'redux-saga/effects';
import { notificationsAPI } from 'services/notifications';
import { GET_NOTIFICATION_LIST_REQUEST } from 'store/reducers/notifications/actionTypes';

function* getNotificationListRequest(
  action: PayloadAction<PayloadActionData<GET_NOTIFICATION_PARAMS>>,
) {
  try {
    const response: AxiosResponse<RESPONSE_TYPE<NOTIFICATION_DATA>> =
      yield call(notificationsAPI.getList, action.payload.data);
    action.payload.onSuccess?.(response.data.data);
  } catch (error) {
    action.payload.onFailed?.(error);
  } finally {
    action.payload.onFinally?.();
  }
}

function* getNotificationList() {
  yield takeLatest(GET_NOTIFICATION_LIST_REQUEST, getNotificationListRequest);
}
export default getNotificationList;
