import { Stack } from '@mui/material';
import { STORAGE_KEY } from 'constants/global';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from 'routers/routes';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { getMyRequestAction } from 'store/reducers/overview/actionTypes';
import { getLocalStorage } from 'utils';
import {
  BoxWrapper,
  ButtonGetStart,
  LabelSubTitle,
  LabelTitle,
} from './styles';

const HowItWorks = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { baseRequest } = useAppSelector((state) => state.overview);

  const boxData = [
    'Create Request',
    'Sitter Matched',
    'Start Trial',
    'Official Sitting',
  ];

  const onStared = async () => {
    navigate(APP_ROUTES.CREATE_REQUEST.to);
  };

  useEffect(() => {
    dispatch(
      getMyRequestAction({
        data: { accessToken: getLocalStorage(STORAGE_KEY.ACCESS_TOKEN) },
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (baseRequest) {
      navigate(APP_ROUTES.OVERVIEW.to);
    }
  }, [baseRequest, navigate]);

  return (
    <Stack paddingY={2}>
      <Stack paddingBottom={4.75}>
        <LabelTitle>Welcome to Aunty Concierge</LabelTitle>
        <LabelSubTitle>How it works</LabelSubTitle>
      </Stack>
      <BoxWrapper>
        {boxData.map((item, index) => {
          return <Stack key={index}>{item}</Stack>;
        })}
      </BoxWrapper>
      <ButtonGetStart type="button" variant="contained" onClick={onStared}>
        Get Started
      </ButtonGetStart>
    </Stack>
  );
};

export default HowItWorks;
