import { Button, Stack, Typography } from '@mui/material';
import { ArrowStrokeIcon, HugeIcon } from 'assets';
import ModalUI from 'components/common/ModalUI';
import PopupConfirm from 'components/popup/PopupConfirm';
import { DATE_TIME_FORMAT } from 'constants/date';
import { SYS_MESS } from 'constants/systemMessage';
import { formatDate } from 'helpers';
import {
  ACTION_TYPE,
  JOB_ITEM_RESPONSE,
  PREVIEW_EXTEND_JOB_RESPONSE,
} from 'models';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from 'routers/routes';
import { useAppDispatch } from 'store/hook';
import { getPreviewExtendJobAction } from 'store/reducers/job/actionTypes';
import { updateDataPaymentJob } from 'store/reducers/payments';
import { theme } from 'theme';
import { pluralString } from 'utils';

interface AcceptExtensionReductionModalProps {
  dataJob: JOB_ITEM_RESPONSE;
  typeSitChange?: ACTION_TYPE;
  _onClose: () => void;
}

export function AcceptExtensionReductionModal({
  dataJob,
  typeSitChange = ACTION_TYPE.EXTEND,
  _onClose,
}: AcceptExtensionReductionModalProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dataExtend, setDataExtend] = useState<PREVIEW_EXTEND_JOB_RESPONSE>();

  const isExtension = typeSitChange === ACTION_TYPE.EXTEND;

  const onConfirm = () => {
    dispatch(
      updateDataPaymentJob({
        id: dataJob.id,
        total: dataExtend.total,
        isTrial: dataJob.is_trial_job,
        isExtend: isExtension,
      }),
    );
    navigate(APP_ROUTES.JOB.PAYMENT.to);
  };

  useEffect(() => {
    dispatch(
      getPreviewExtendJobAction({
        data: { id: dataJob.id },
        onSuccess: (data: PREVIEW_EXTEND_JOB_RESPONSE) => {
          setDataExtend(data);
        },
      }),
    );
  }, [dataJob, dispatch]);

  if (!dataExtend) return;

  return (
    <ModalUI
      open
      onClose={_onClose}
      maxW={500}
      sx={{
        '& .MuiBox-root': { pt: 2 },
      }}
    >
      <Stack>
        <Stack alignItems={'center'} pb={5}>
          <Typography fontSize={24} fontWeight={700}>
            Sit Change
          </Typography>
          <Typography variant="Web_Label_18" sx={{ opacity: 0.5 }}>
            {isExtension
              ? 'Extension of sit duration'
              : 'Reduction of sit duration'}
          </Typography>
        </Stack>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Stack spacing={1.5}>
            <Typography variant="Small_Profile_16">Current timing</Typography>
            <Stack spacing={1}>
              <Typography variant="Small_Body_16">
                {formatDate(dataExtend.current_job.start_datetime, {
                  format: DATE_TIME_FORMAT.GENERAL,
                })}
              </Typography>
              <Typography variant="Small_Body_16">
                {formatDate(dataExtend.current_job.end_datetime, {
                  format: DATE_TIME_FORMAT.GENERAL,
                })}
              </Typography>
              <Typography variant="Web_Label_16">
                {pluralString(
                  'hour',
                  dataExtend.current_job.total_hours_origin,
                )}
              </Typography>
            </Stack>
          </Stack>
          <ArrowStrokeIcon />
          <Stack spacing={1.5}>
            <Typography variant="Small_Profile_16" color="primary">
              New Timing
            </Typography>
            <Stack spacing={1}>
              <Typography variant="Small_Body_16">
                {formatDate(dataExtend.extended_job.start_datetime, {
                  format: DATE_TIME_FORMAT.GENERAL,
                })}
              </Typography>
              <Typography variant="Small_Body_16">
                {formatDate(dataExtend.extended_job.end_datetime, {
                  format: DATE_TIME_FORMAT.GENERAL,
                })}
              </Typography>
              <Typography variant="Web_Label_16">
                {pluralString(
                  'hour',
                  dataExtend.current_job.total_hours_origin +
                    dataExtend.extended_job.total_hours,
                )}
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack spacing={1} direction={'row'} pt={4.5}>
          <HugeIcon
            sx={{
              color: isExtension
                ? theme.palette.primary.main
                : theme.palette.secondary.main,
              fontSize: 16,
            }}
          />
          <Typography
            variant="Components_Column_content"
            color={isExtension ? 'primary' : 'secondary'}
          >
            Note: The sit hour has been extended, an additional charges of&nbsp;
            <Typography variant="Mobile_Caption_Medium" component="span">
              ${dataExtend.total}
            </Typography>
            &nbsp;will be deducated from your credit upon confirmation.
          </Typography>
        </Stack>
        <Stack direction={'row'} pt={3} spacing={2}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={_onClose}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={onConfirm}
          >
            {isExtension ? 'Confirm & Pay' : 'Confirm'}
          </Button>
        </Stack>
      </Stack>
      {isOpen && (
        <PopupConfirm
          title={SYS_MESS.SUCCESS.BOOKING_UPDATED}
          content={
            <Typography variant="Web_Label_14">
              Your booking has been successfully updated.
              <br /> Credits will be deducted accordingly from your plan.
            </Typography>
          }
          textButton="Back to Bookings"
          handleClose={() => {
            setIsOpen(false);
            _onClose();
          }}
        />
      )}
    </ModalUI>
  );
}

export default AcceptExtensionReductionModal;
