import { yupResolver } from '@hookform/resolvers/yup';
import { Avatar, Button, Divider, Stack, Typography } from '@mui/material';
import { CheckedIconCircle, RadioUnCheckIcon } from 'assets';
import {
  DatePickerField,
  InputField,
  PopupAddEditAddress,
  SelectField,
} from 'components';
import InlineError from 'components/common/ErrorInline';
import CheckboxField from 'components/form-control/CheckboxField';
import TimePickerField from 'components/form-control/TimePickerField';
import yup from 'config/yup.custom';
import { SYS_MESS } from 'constants/systemMessage';
import { ACTION_TYPE } from 'models';
import {
  AC_BASE_REQUEST_MODAL_TYPE,
  REQUEST_DETAIL_DATA,
} from 'models/create-request';
import { CHILDREN_LIST_RESPONSE } from 'models/parent/children';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hook';
import {
  getAddressListAction,
  getChildrenListAction,
} from 'store/reducers/parent/actionTypes';
import {
  updateBodyCreateRequest,
  updateStepTrial,
} from 'store/reducers/trial-request';
import { theme } from 'theme';
import { checkEqualDate } from 'utils';
import { ageRangeOptions, dataPets, daysOfWeekOptions } from '../../helpers';
import TermsModal from './components/TermsModal';
import {
  Actions,
  AddressAction,
  FormContainer,
  LabelCheckbox,
  MoreActions,
} from './styles';

const schema = yup
  .object()
  .shape({
    startDate: yup.mixed().required(SYS_MESS.ERROR.START_DATE_REQUIRED),
    endDate: yup.mixed().required(SYS_MESS.ERROR.END_DATE_REQUIRED),
    startTime: yup.mixed().required(SYS_MESS.ERROR.START_TIME_REQUIRED),
    endTime: yup.mixed().required(SYS_MESS.ERROR.END_TIME_REQUIRED),
    dayOfWeeks: yup.array().min(1, SYS_MESS.ERROR.DAY_OF_WEEK_REQUIRED),
    address: yup.mixed().required(SYS_MESS.ERROR.ADDRESS_REQUIRED),
    children: yup.array().min(1, SYS_MESS.ERROR.CHILDREN_REQUIRED),
    specialRequirements: yup.string().optional(),
    isAccepted: yup
      .boolean()
      .test(
        'isChecked',
        SYS_MESS.ERROR.CHECKBOX_REQUIRED,
        (value) => value === true,
      )
      .required(SYS_MESS.ERROR.CHECKBOX_REQUIRED),
  })
  .required();

function RequestDetail() {
  const dispatch = useAppDispatch();
  const { bodyCreateRequest } = useAppSelector((state) => state.trialRequest);
  const { childrenList, addressList } = useAppSelector((state) => state.parent);

  const [isOpenAddressModal, setIsOpenAddressModal] = useState<boolean>(false);
  const [isOpenTermModal, setIsOpenModal] = useState<boolean>(false);
  const [isEditAddress, setIsEditAddress] = useState<boolean>(false);

  const form = useForm<REQUEST_DETAIL_DATA>({
    resolver: yupResolver(schema),
    defaultValues: {
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
      dayOfWeeks: [],
      address: null,
      children: [],
      pets: [],
      specialRequirements: '',
      isAccepted: false,
    },
  });

  const { handleSubmit, control, formState, setValue, watch } = form;

  const onSubmit = (data: REQUEST_DETAIL_DATA) => {
    setIsOpenModal(true);
    dispatch(
      updateBodyCreateRequest({
        ...bodyCreateRequest,
        requestDetail: {
          ...data,
          startDate: moment(data.startDate),
          endDate: moment(data.endDate),
        },
      }),
    );
  };

  const handleOpenAddress = (type: ACTION_TYPE) => {
    setIsOpenAddressModal(true);
    setIsEditAddress(type === ACTION_TYPE.EDIT);
  };

  const onCloseModal = (modalType: AC_BASE_REQUEST_MODAL_TYPE) => {
    if (modalType === AC_BASE_REQUEST_MODAL_TYPE.TERMS) {
      setIsOpenModal(false);
    } else setIsOpenAddressModal(false);
  };

  const LabelChild = (child: CHILDREN_LIST_RESPONSE) => {
    return (
      <Stack direction={'row'} alignItems={'center'} gap={1}>
        <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
          {child.full_name[0].toUpperCase()}
        </Avatar>
        <Stack>
          <Typography variant="Small_Body_13_Bold">
            {child.full_name}
          </Typography>
          <Typography variant="Small_Body_13">
            {
              ageRangeOptions.find((range) => range.id === child.age_range)
                ?.name
            }
          </Typography>
        </Stack>
      </Stack>
    );
  };

  useEffect(() => {
    dispatch(getAddressListAction());
    dispatch(getChildrenListAction());
  }, [dispatch]);

  useEffect(() => {
    if (bodyCreateRequest?.requestDetail) {
      setValue('startDate', moment(bodyCreateRequest.requestDetail.startDate));
      setValue('endDate', moment(bodyCreateRequest.requestDetail.endDate));
      setValue('startTime', bodyCreateRequest.requestDetail.startTime);
      setValue('endTime', bodyCreateRequest.requestDetail.endTime);
      setValue('dayOfWeeks', bodyCreateRequest.requestDetail.dayOfWeeks);
      setValue('address', bodyCreateRequest.requestDetail.address);
      setValue('children', bodyCreateRequest.requestDetail.children);
      setValue('pets', bodyCreateRequest.requestDetail.pets);
      setValue(
        'specialRequirements',
        bodyCreateRequest.requestDetail.specialRequirements,
      );
      setValue('isAccepted', bodyCreateRequest.requestDetail.isAccepted);
    }
  }, [bodyCreateRequest, setValue]);

  const getMinTimeStart = useMemo(() => {
    // Get current hour (0-23) and minutes
    const originalHours = new Date().getHours() % 24;
    const originalMins = new Date().getMinutes();

    // Check if selected date is today
    if (checkEqualDate(watch('startDate')?.format('MM/DD/YYYY'))) {
      // If today, minimum time is current time
      return new Date(0, 0, 0, originalHours, originalMins);
    }
    // If not today, minimum time is start of day (00:00)
    return new Date(0, 0, 0, 0, 0);
    // eslint-disable-next-line
  }, [watch('startDate')]);

  const getMinTimeEnd = useMemo(() => {
    // Get hours and minutes from the selected start time
    const originalHours = watch('startTime')?.getHours();
    const originalMins = watch('startTime')?.getMinutes();

    // Calculate new hours by adding 2 hours to start time
    // % 24 ensures hours wrap around correctly (e.g., 23 + 2 = 1)
    const newHours = (originalHours + 2) % 24;
    return new Date(0, 0, 0, newHours, originalMins);
    // eslint-disable-next-line
  }, [watch('startTime')]);

  const getMaxTime = useMemo(() => {
    return new Date(0, 0, 0, 23, 59);
    // eslint-disable-next-line
  }, [watch('startTime'), watch('endDate')]);

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <TermsModal
        open={isOpenTermModal}
        onClose={() => onCloseModal(AC_BASE_REQUEST_MODAL_TYPE.TERMS)}
      />
      {isOpenAddressModal && (
        <PopupAddEditAddress
          open={isOpenAddressModal}
          onClose={() => onCloseModal(AC_BASE_REQUEST_MODAL_TYPE.ADDRESS)}
          isEdit={isEditAddress}
          itemSelected={watch('address')}
        />
      )}
      <Stack flexDirection={'row'} gap={2}>
        <DatePickerField
          control={control}
          name={'startDate'}
          iconColor="primary"
          placeholder="Expected Start Date"
          label="Start Date"
          minDate={moment()}
        />
        <DatePickerField
          control={control}
          name={'endDate'}
          iconColor="primary"
          placeholder="Expected End Date"
          label="End Date"
          minDate={watch('startDate')}
        />
      </Stack>
      <Stack flexDirection={'row'} gap={2}>
        <TimePickerField
          control={control}
          name={'startTime'}
          placeholderText="Expected Start Time"
          label="Start Time"
          minTime={getMinTimeStart}
          maxTime={getMaxTime}
        />
        <TimePickerField
          control={control}
          name={'endTime'}
          placeholderText="End Time"
          label="End Time"
          minTime={getMinTimeEnd}
          maxTime={getMaxTime}
        />
      </Stack>
      <Stack alignItems={'left'}>
        <Stack
          flexDirection={'row'}
          gap={3.5}
          sx={{ margin: 'auto', '& label': { flexDirection: 'column' } }}
        >
          {daysOfWeekOptions.map((item) => (
            <Controller
              key={item.id}
              name="dayOfWeeks"
              control={control}
              render={({ field: { value, onChange } }) => (
                <CheckboxField
                  label={item.name}
                  checked={value?.includes(String(item.id))}
                  icon={<RadioUnCheckIcon />}
                  checkedIcon={<CheckedIconCircle />}
                  onChange={(e) => {
                    const currValue = value || [];
                    const newValue = e.target.checked
                      ? [...currValue, String(item.id)]
                      : currValue.filter((id) => id !== String(item.id));

                    onChange(newValue);
                  }}
                />
              )}
            />
          ))}
        </Stack>
        <InlineError
          open={!!formState.errors.dayOfWeeks}
          error={formState.errors.dayOfWeeks?.message}
        />
      </Stack>

      <Stack spacing={2}>
        <SelectField
          control={control}
          name="address"
          label="Address"
          placeholder="Select one"
          bindKey="id"
          bindLabel="building_name"
          options={addressList}
        />
        <Stack direction={'row'} gap={2}>
          <AddressAction
            onClick={() => handleOpenAddress(ACTION_TYPE.EDIT)}
            sx={{ pointerEvents: watch('address')?.id ? 'auto' : 'none' }}
          >
            Edit Address
          </AddressAction>
          <AddressAction onClick={() => handleOpenAddress(ACTION_TYPE.CREATE)}>
            Add New Address
          </AddressAction>
        </Stack>
      </Stack>
      <Stack>
        <Typography variant="Small_Body_16">Any pets in the house?</Typography>
        <Stack>
          {dataPets.map((item) => (
            <Controller
              key={item.id}
              name="pets"
              control={control}
              render={({ field: { value, onChange } }) => (
                <CheckboxField
                  label={item.name}
                  checked={value.includes(item.name)}
                  onChange={(e) => {
                    const currValue = value || [];
                    const newValue = e.target.checked
                      ? [...currValue, item.name]
                      : currValue.filter((name) => name !== item.name);

                    onChange(newValue);
                  }}
                />
              )}
            />
          ))}
          <InlineError
            open={!!formState.errors.pets}
            error={formState.errors.pets?.message}
          />
        </Stack>
      </Stack>
      <Stack spacing={1.5}>
        <Typography variant="Small_Body_16">Child</Typography>
        <Stack spacing={2}>
          {childrenList.map((child) => (
            <Controller
              key={child.id}
              name="children"
              control={control}
              render={({ field }) => (
                <CheckboxField
                  label={LabelChild(child)}
                  checked={field.value?.some((item) => item.id === child.id)}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    const currValue = field.value || [];
                    const newValue = isChecked
                      ? [...currValue, child]
                      : currValue.filter((item) => item.id !== child.id);

                    field.onChange(newValue);
                  }}
                />
              )}
            />
          ))}
        </Stack>
        <InlineError
          open={!!formState.errors.children}
          error={formState.errors.children?.message}
        />
      </Stack>
      <InputField
        control={control}
        name={'specialRequirements'}
        label="Special Requirements"
        placeholder="Other Requirements"
        multiline
        minRows={3}
        maxRows={5}
      />
      <MoreActions>
        <Divider sx={{ borderColor: '#D9D9D9' }} />
        <CheckboxField
          name="isAccepted"
          control={control}
          label={
            <LabelCheckbox>
              I acknowledge that I have read and understood the
              <span>&nbsp;Data Protection Notice&nbsp;</span>, and consent to
              the collection, use and disclosure of my personal data by Aunty SG
              for the purposes set out in the Notice.
            </LabelCheckbox>
          }
        />
        <Actions>
          <Button
            variant="contained"
            type="submit"
            fullWidth
            disabled={!watch('startTime') || !watch('endTime')}
          >
            Next
          </Button>
          <Button
            variant="text"
            fullWidth
            onClick={() => dispatch(updateStepTrial(1))}
          >
            Back
          </Button>
        </Actions>
      </MoreActions>
    </FormContainer>
  );
}

export default RequestDetail;
