import {
  Avatar,
  Box,
  Container,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { APP_IMG, LogoIcon, MenuMobileIcon } from 'assets';
import NotificationIcon from 'assets/icons/NotificationIcon';
import { STORAGE_KEY } from 'constants/global';
import { useAuth } from 'contexts/auth';
import { MouseEvent, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { APP_ROUTES } from 'routers/routes';
import { useAppDispatch, useAppSelector } from 'store/hook';
import {
  setNewestNotify,
  setNotificationListOnBell,
} from 'store/reducers/notifications';
import { getNotificationListAction } from 'store/reducers/notifications/actionTypes';
import { theme } from 'theme';
import { getLocalStorage, setLocalStorage } from 'utils';
import MenuMobile from '../Menu/MenuMobile';
import AccountPendingInfo from './components/AccountPendingInfo';
import NotifyBell from './components/NotifyBell';
import { MenuStyled } from './styles';

export const Header = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { logout } = useAuth();
  const { parent } = useAppSelector((state) => state.auth);
  const { baseRequest } = useAppSelector((state) => state.overview);
  const { isNewestNotify, notificationsOnBell } = useAppSelector(
    (state) => state.notifications,
  );
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [openStatusInfo, setOpenStatusInfo] = useState<boolean>(false);
  const [loadingPopup, setIsLoadingPopup] = useState<boolean>(false);

  const [anchorProfileEl, setAnchorProfileEl] = useState<null | HTMLElement>(
    null,
  );
  const openProfileMenu = Boolean(anchorProfileEl);

  const [anchorNotifyEl, setAnchorNotifyEl] = useState<null | HTMLElement>(
    null,
  );
  const openBellMenu = Boolean(anchorNotifyEl);

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    type: 'profile' | 'notify',
  ) => {
    if (type === 'notify') {
      setAnchorNotifyEl(event.currentTarget);
      return;
    }
    setAnchorProfileEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorProfileEl(null);
    setAnchorNotifyEl(null);
    if (openBellMenu) {
      dispatch(setNewestNotify(false));
      setLocalStorage(STORAGE_KEY.IS_NEWEST_NOTIFY, false.toString());
    }
  };

  const getNotifications = () => {
    setIsLoadingPopup(true);
    dispatch(
      getNotificationListAction({
        data: {
          page: 1,
          limit: 3,
        },
        onSuccess: (data) => {
          setIsLoadingPopup(false);
          dispatch(setNotificationListOnBell(data));
        },
      }),
    );
  };

  useEffect(() => {
    setOpenMenu(false);
  }, [location]);

  return (
    <Box
      pt={2.25}
      pb={{ xs: 2, md: 3 }}
      component="section"
      position="sticky"
      top={0}
      zIndex={10}
      borderBottom="1px solid var(--light-other-stroke, rgba(0, 0, 0, 0.08))"
      bgcolor={theme.palette.white.light}
    >
      <Container>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" spacing={5} alignItems="center">
            <Link
              to={
                !baseRequest
                  ? APP_ROUTES.HOW_IT_WORKS.to
                  : APP_ROUTES.OVERVIEW.to
              }
            >
              <LogoIcon
                sx={{
                  color: theme.palette.primary.main,
                  width: { xs: '71px', md: '89px' },
                  height: { xs: '51px', md: '63px' },
                }}
              />
            </Link>
          </Stack>
          <AccountPendingInfo
            open={openStatusInfo}
            onClose={() => setOpenStatusInfo(false)}
          />
          <Stack direction="row" alignItems="center" spacing={1}>
            <>
              <IconButton
                onClick={(e: MouseEvent<HTMLElement>) =>
                  handleClick(e, 'notify')
                }
              >
                <NotificationIcon
                  sx={{
                    color: 'gray.dark_grey',
                    '& > circle': {
                      color:
                        isNewestNotify ||
                        getLocalStorage(STORAGE_KEY.IS_NEWEST_NOTIFY) ===
                          true.toString()
                          ? 'primary.main'
                          : 'grey1.light',
                    },
                  }}
                />
              </IconButton>
              {openBellMenu && (
                <NotifyBell
                  loadingPopup={loadingPopup}
                  anchorEl={anchorNotifyEl}
                  open={openBellMenu}
                  _onClick={handleClick}
                  _onClose={handleClose}
                  notifications={notificationsOnBell}
                  onRefreshData={getNotifications}
                />
              )}
            </>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <Typography
                component="p"
                textAlign="right"
                variant="Small_Profile_14"
              >
                Hello Parent
              </Typography>
              <Typography
                color={theme.palette.primary.main}
                component="p"
                variant="Small_Profile_16"
              >
                {parent?.full_name || 'Test'}
              </Typography>
            </Box>
            <IconButton
              onClick={(e: MouseEvent<HTMLElement>) =>
                handleClick(e, 'profile')
              }
              sx={{ display: { xs: 'none', md: 'block' } }}
            >
              <Avatar
                alt="avatar_parent"
                src={parent?.profile_picture || APP_IMG.defaultAvatar}
                sx={{ width: 35, height: 35 }}
              />
            </IconButton>
            <MenuStyled
              anchorEl={anchorProfileEl}
              open={openProfileMenu}
              onClose={handleClose}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem onClick={logout}>
                <Typography variant="Title_Menu_14">Logout</Typography>
              </MenuItem>
            </MenuStyled>
            <IconButton
              color="primary"
              sx={{
                display: { xs: 'block', md: 'none' },
                width: '40px',
                height: '40px',
              }}
              aria-label="MenuMobile"
              onClick={() => setOpenMenu(true)}
            >
              <MenuMobileIcon />
            </IconButton>
          </Stack>
        </Stack>
      </Container>
      <MenuMobile open={openMenu} onClose={() => setOpenMenu(false)} />
    </Box>
  );
};

export default Header;
