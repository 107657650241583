import { GlobalLoading } from 'components';
import { STORAGE_KEY } from 'constants/global';
import {
  useConnectWebSocket,
  useHandleNotification,
  useInitTimeZone,
} from 'hooks';
import { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configAppRoutes from 'routers';
import { useAppSelector } from 'store/hook';
import { ThemeProvider } from 'theme';
import { getLocalStorage } from 'utils';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import 'yet-another-react-lightbox/styles.css';

const App = () => {
  useInitTimeZone();
  const routes = useRoutes(configAppRoutes);
  const { globalLoading } = useAppSelector((state) => state.global);
  const { loadingAuth } = useAppSelector((state) => state.auth);
  const { paymentIntentId } = useAppSelector((state) => state.payment);

  const token = getLocalStorage(STORAGE_KEY.ACCESS_TOKEN);
  useConnectWebSocket({
    token: token,
    paymentIntentId: paymentIntentId,
  });
  useHandleNotification();

  return (
    <Suspense fallback={<GlobalLoading open />}>
      <ThemeProvider>
        {!loadingAuth && routes}
        <GlobalLoading open={globalLoading} />
        <ToastContainer />
      </ThemeProvider>
    </Suspense>
  );
};

export default App;
