import { Stack, Typography } from '@mui/material';
import { PaymentComponent } from 'components';
import { NotifyService } from 'config';
import { STORAGE_KEY } from 'constants/global';
import { SYS_MESS } from 'constants/systemMessage';
import { PAYMENT_MODAL_TYPE, PAYMENT_TYPE } from 'models/payments';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import {
  checkoutSearchFeeAction,
  paySearchFeeAction,
} from 'store/reducers/payments/actionTypes';
import { updateStepTrial } from 'store/reducers/trial-request';
import { convertToUtcTime, formatPrice, mergeDateTime } from 'utils';

function RequestPayment() {
  const dispatch = useAppDispatch();
  const { bodyCreateRequest } = useAppSelector((state) => state.trialRequest);
  const { paymentMethodId, checkoutData } = useAppSelector(
    (state) => state.payment,
  );

  useEffect(() => {
    if (!bodyCreateRequest.requestDetail) {
      dispatch(updateStepTrial(1));
    }
    // eslint-disable-next-line
  }, []);

  const _dataSubmitRequestDetail = {
    start_datetime: convertToUtcTime(
      mergeDateTime(
        bodyCreateRequest.requestDetail.startDate,
        bodyCreateRequest.requestDetail.startTime,
      ),
    ),
    end_datetime: convertToUtcTime(
      mergeDateTime(
        bodyCreateRequest.requestDetail.endDate,
        bodyCreateRequest.requestDetail.endTime,
      ),
    ),
    days_of_week: bodyCreateRequest.requestDetail.dayOfWeeks
      .map(Number)
      .sort((a, b) => a - b),
    address_id: bodyCreateRequest.requestDetail.address.id,
    children_ids: bodyCreateRequest.requestDetail.children.map(
      (item) => item.id,
    ),
    pets: {
      cats: bodyCreateRequest.requestDetail.pets.includes('Cats'),
      dogs: bodyCreateRequest.requestDetail.pets.includes('Dogs'),
    },
    special_requirements: bodyCreateRequest.requestDetail.specialRequirements,
  };

  const handleBackAction = () => {
    dispatch(updateStepTrial(2));
  };

  const checkoutAction = (isPayNow: boolean) => {
    dispatch(
      checkoutSearchFeeAction({
        data: {
          payment_method_type: isPayNow
            ? PAYMENT_MODAL_TYPE.PAY_NOW
            : PAYMENT_MODAL_TYPE.CARD,
        },
        onFailed: () => {
          handleBackAction();
        },
      }),
    );
  };

  const paymentCallback = (isPayNow: boolean, callback?: Function) => {
    const _params = {
      payment_method_id: !isPayNow ? paymentMethodId : undefined,
      payment_method_type: isPayNow
        ? PAYMENT_MODAL_TYPE.PAY_NOW
        : PAYMENT_MODAL_TYPE.CARD,
      ..._dataSubmitRequestDetail,
    };

    dispatch(
      paySearchFeeAction({
        data: _params,
        onSuccess: (qrCode: string) => {
          if (isPayNow) callback?.(qrCode);
          else {
            dispatch(updateStepTrial(4));
            NotifyService.success(SYS_MESS.SUCCESS.PAYMENT);
          }
        },
        onFailed: () => {},
        onFinally: () => {},
      }),
    );
  };

  return (
    <React.Fragment>
      <PaymentComponent
        moreFeeChildren={
          <Stack direction={'row'}>
            <Typography variant="Components_Column_content" color={'#6d6d6d'}>
              Search Fee
            </Typography>
            <Typography variant="Components_Column_content" color={'#6d6d6d'}>
              {formatPrice(checkoutData.amount)}
            </Typography>
          </Stack>
        }
        paymentType={PAYMENT_TYPE.SEARCH_FEE}
        storageKey={STORAGE_KEY.QR_CODE_SEARCH_FEE}
        paymentCallback={paymentCallback}
        handleBackAction={handleBackAction}
        checkoutAction={checkoutAction}
      />
    </React.Fragment>
  );
}

export default RequestPayment;
