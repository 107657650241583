import { DATE_TIME_FORMAT } from 'constants/date';
import moment from 'moment-timezone';

export const formatDate = (
  value: moment.MomentInput,
  options?: { format?: string; empty?: string; valFormat?: string },
) => {
  return value
    ? moment(value, options?.valFormat).format(
        options?.format || DATE_TIME_FORMAT.NORMAL_DATE,
      )
    : options?.empty || '';
};
