import { Stack, Typography } from '@mui/material';
import SitterPanel from 'components/common/SitterPanel';
import { CHECK_LIST_EXPERIENCE_SITTER } from 'models/sitter';

interface ExperienceSectionProps {
  checkList: CHECK_LIST_EXPERIENCE_SITTER[];
}

export const ExperienceSection = ({ checkList }: ExperienceSectionProps) => {
  return (
    <Stack spacing={1}>
      <Typography variant="Web_Title_22">Experience</Typography>
      <Stack spacing={{ xs: 1, md: 2 }}>
        {checkList.map((exp: CHECK_LIST_EXPERIENCE_SITTER) => (
          <SitterPanel
            key={exp.id}
            panelItem={{
              section_title: exp.section_name,
              items: exp.items.filter((item) => item.is_checked),
            }}
          />
        ))}
      </Stack>
    </Stack>
  );
};
